import React, { PureComponent } from "react"
import "./password-input.scss"
import classnames from "classnames"

import Input from "../Input"

class PasswordInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
    }
  }

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    let { placeholder, ...rest } = this.props
    return (
      <Input
        type={this.state.showPassword ? "text" : "password"}
        wrapperClass={"password-input"}
        placeholder={placeholder}
        {...rest}
      >
        <i
          className={classnames("iconfont", [
            this.state.showPassword ? "iconyanjing" : "iconpwd-hidden",
          ])}
          onClick={this.togglePasswordVisibility}
        />
      </Input>
    )
  }
}

export default PasswordInput
