/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, no-script-url */
import React, { PureComponent } from "react"
import { Flex, WhiteSpace, List } from "antd-mobile"
import "./index.scss"
import Avatar from "./image/avatar.png"
import Vip from "./image/vip.png"
import { WithTab } from "src/HOCs"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { HeaderBar } from "src/common"
import { http } from "src/utils"
import { setCurrentUser, startFetchUser } from "src/store/userAction"
import { endFetchNoTrace } from "src/store/no-trace-validation/reducer"

const Item = List.Item
const Brief = Item.Brief

@connect(
  (state) => ({
    user: state.user,
  }),
  { startFetchUser, setCurrentUser, endFetchNoTrace }
)
class My extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.getUser()
  }

  getUser = () => {
    this.props.startFetchUser()
    http.get(`${API.home}/m/user_info_sample/1`).then((res) => {
      this.props.endFetchNoTrace()
      this.props.setCurrentUser(this.transformUser(res))
    })
  }

  transformUser = (res) => {
    let payload
    if (res.data.code === 200) {
      const {
        msg,
        data: {
          avatar_file: avatar,
          user_name: username,
          is_vip: isVIP,
          uid,
          code,
        },
      } = res.data

      payload = {
        hasError: false,
        msg,
        data: {
          username,
          isVIP,
          avatar,
          uid,
          code,
        },
      }
    } else {
      payload = {
        hasError: true,
        msg: res.data.msg,
        code: res.data.code,
        data: {},
      }
    }
    return payload
  }

  // 去登录
  toLogin = () => {
    this.props.history.push("/passport")
  }

  toCourseDetail = (id) => {
    const { history } = this.props
    history.push(`/detail?id=${id}`)
    return false
  }

  render() {
    const { user } = this.props
    const uid = user && user.data && user.data.uid
    const username = user && user.data && user.data.username
    const avatar = user && user.data && user.data.avatar
    const isVIP = user && user.data && user.data.isVIP

    let list
    if (!uid) {
      list = (
        <List className="my-list">
          <Item
            className="avatar-wrap"
            arrow="horizontal"
            multipleLine
            thumb={Avatar}
            onClick={this.toLogin}
          >
            <Brief>点击登录</Brief>
          </Item>
        </List>
      )
    } else {
      list = (
        <List className="my-list">
          <Link to="/myedit">
            <Item
              className="avatar-wrap"
              arrow="horizontal"
              multipleLine
              thumb={avatar}
            >
              <Brief>
                {username}
                {isVIP === 1 && <img src={Vip} className="vip" alt="" />}
              </Brief>
              <Brief style={{ fontSize: "12px" }}>学号: {uid}</Brief>
            </Item>
          </Link>
          {!isVIP && (
            <a
              href="javascript:;"
              className="my-isvip"
              onClick={() => this.toCourseDetail(139)}
            ></a>
          )}
        </List>
      )
    }
    return (
      <div className="flex-container">
        <Flex>
          <Flex.Item>
            <HeaderBar title="我的" arrow={false} cart={false}></HeaderBar>

            {list}

            {!uid && <div className="am-list-header"></div>}

            <List className="my-list-content">
              <Link to="/purchased">
                <Item arrow="horizontal">
                  <i className="iconfont iconiconfront-27"></i>
                  已购课程
                </Item>
              </Link>
              <Link to="/shopcart">
                <Item arrow="horizontal">
                  <i className="iconfont icongouwuche-xianxing"></i>
                  购物车
                </Item>
              </Link>
              <Link to="/myorders">
                <Item arrow="horizontal">
                  <i className="iconfont iconiconfront-24"></i>
                  课程订单
                </Item>
              </Link>
              <Link
                to={{
                  pathname: "/coupons",
                  state: {
                    from: this.props.location.pathname,
                  },
                }}
              >
                <Item arrow="horizontal" className="no-border">
                  <i className="iconfont iconiconfront-52"></i>
                  优惠券
                </Item>
              </Link>
              <div className="am-list-header"></div>

              <Link to="/scholarship">
                <Item arrow="horizontal" className="btm-scholarship">
                  <i className="iconfont iconiconfront-51"></i>
                  赚奖学金
                </Item>
              </Link>
            </List>
          </Flex.Item>
        </Flex>
        <WhiteSpace size="lg" />
      </div>
    )
  }
}

export default WithTab(My)
