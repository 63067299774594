import React, { Component } from "react"
import "./clearable-input.scss"
import classnames from "classnames"

class ClearableInput extends Component {
  render() {
    let {
      value,
      name,
      wrapperClass,
      inputClass,
      type = "text",
      icon,
      setFieldValue,
      ...rest
    } = this.props
    let clearIconStyle = {
      display: value && value.length ? "block" : "none",
    }
    return (
      <div className={classnames("clearable-input-wrapper", wrapperClass)}>
        <input
          type={type}
          value={value}
          className={inputClass}
          {...rest}
          name={name}
        />
        {icon}
        <i
          className={"iconfont icondanseshixintubiao-3 clear"}
          onClick={() => {
            setFieldValue(name, "")
          }}
          style={clearIconStyle}
        />
      </div>
    )
  }
}

export default ClearableInput
