import React, { Component } from "react"
import "./index.scss"
import { http, browser, validateTel } from "src/utils"
import { Toast } from "antd-mobile"
import { Link } from "react-router-dom"

class LiveRoom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roomMess: "",
      iphone: "", // 手机号
      toSubscribe: false, // 点击预约之后，判断判断时候关注公众号，选择预约方式
      isfollow: 1, // 是否关注公众号  0没有 1有
      step: 0, // 手机号预约还是公众号预约
      success: false, // 预约成功
    }
  }

  // 课程直播信息
  componentDidMount() {
    // this.getRoom()
    if (browser.isWeixin) {
      this.isFollow()
    }
  }

  // // 课程直播信息
  // getRoom = () => {
  //     http.get(`${API.home}/m/live/middle/${this.props.roomMess.room_id}`).then((res) => {
  //         if (res.data.code === 200) {
  //             // this.setState({
  //             //     roomMess: res.data.data
  //             // })
  //         } else {
  //             Toast.info(res.data.msg, 2)
  //         }
  //
  //     })
  // }

  // 是否关注服务号
  isFollow = () => {
    http.get(`${API.home}/m/live/isFollow`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          isfollow: res.data.data.status,
        })
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }

  // 直播间预约接口公共方法
  livePrepare = (room_id, type, source, mobile) => {
    let data = {
      room_id: room_id, // 直播间id
      type: type, // 1-为微信服务通知预约 2-为手机号预约
      source: source, //  1-为微信内置浏览器 2-为wap（微信外）
      mobile: mobile, // 手机号 当type为2时，该参数为必填
    }
    http.post(`${API.home}/m/live/prepare`, data).then((res) => {
      /* status  0表示预约成功
                       1表示预约失败
                       2表示未关注服务号
                       3表示已预约
                       4表示手机号不能为空
            */
      if (res.data.code === 200) {
        if (res.data.data.status === 0) {
          // 如果是微信服务通知预约成功 需要提示预留手机号。
          if (type === 1) {
            this.setState({
              toSubscribe: true,
              step: 2,
            })
          } else {
            // 手机号预约成功直接弹出'预约成功'
            Toast.info(res.data.data.msg, 2)
            this.setState({
              success: true,
              toSubscribe: false,
            })
            this.props.getIndexData()
          }
        } else if (res.data.data.status === 1) {
          Toast.info(res.data.data.msg, 2)
        } else if (res.data.data.status === 3) {
          Toast.info(res.data.data.msg, 2)
          this.setState({
            toSubscribe: false,
          })
          this.props.getIndexData()
        } else if (res.data.data.status === 5) {
          window.location.href =
            "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIyMzMzMTIwNA==&scene=124#wechat_redirect"
        } else {
          Toast.info(res.data.data.msg, 2)
        }
      }
    })
  }

  // 关闭弹窗
  liveColse = () => {
    this.props.colseBox(false)
  }

  // 点击预约
  toSubscribeFun = () => {
    this.props.colseBox(false)
    this.setState({
      toSubscribe: true,
      step: browser.isWeixin ? 0 : 1,
    })
  }

  // 获取手机号
  handleChange = (e) => {
    this.setState({
      iphone: e.target.value,
    })
  }

  // 手机号预约
  iphoneStep = () => {
    this.setState({
      step: 1,
    })
  }

  // 服务号预约
  serverStep = () => {
    if (Number(this.state.isfollow) === 2) {
      window.location.href =
        "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIyMzMzMTIwNA==&scene=124#wechat_redirect"
    } else {
      this.livePrepare(this.props.roomMess.room_id, 1, 1, "")
    }
  }

  // 手机号预约 || 预留手机号
  submit = () => {
    // 手机号预约
    if (this.state.step === 1) {
      if (!validateTel(this.state.iphone)) {
        Toast.info("请填写正确格式的手机号", 2)
      } else {
        this.livePrepare(this.props.roomMess.room_id, 2, 2, this.state.iphone)
      }
    }
    // 公众号预约预留手机号
    if (this.state.step === 2) {
      let data = {
        mobile: this.state.iphone,
        room_id: this.props.roomMess.room_id,
      }
      http.post(`${API.home}/m/live/wxReservedTel`, data).then((res) => {
        if (res.data.code === 200) {
          this.setState({
            toSubscribe: false,
          })
          Toast.info(res.data.msg, 2)
          this.props.getIndexData()
        } else if (res.data.code === 410) {
          Toast.info("请输入手机号", 2)
        } else {
          Toast.info(res.data.msg, 2)
        }
      })
    }
  }

  // 取消预约 关闭弹窗
  subscribeColse = () => {
    this.setState({
      toSubscribe: false,
    })
  }

  render() {
    const roomMess = this.props.roomMess
    return (
      <div className="index-box">
        {/* 直播间预约 */}

        {this.props.isShow === true && (
          <div className="live-room-box">
            <div className="live-room-content">
              <div className="course-banner">
                <div className="left">
                  <span></span>
                </div>
                <div className="center">
                  <img src={roomMess.live_img} alt="" />
                </div>
                <div className="right">
                  <span></span>
                </div>
              </div>
              <div className="course-dec">
                <h2 className="title text-overflow-2">{roomMess.live_title}</h2>
                <p className="teacher text-overflow-2">
                  讲师：{roomMess.live_teacher_name}
                </p>
                <p className="time">时间：{roomMess.live_start_time}</p>
              </div>
              <div className="course-info">
                <ul>
                  <li>简介：</li>
                  <li className="text-overflow-2">{roomMess.live_desc}</li>
                </ul>
              </div>
              <div className="course-subscribe">
                {!roomMess.is_prepare && roomMess.is_free === 1 && (
                  <button onClick={this.toSubscribeFun}>预约</button>
                )}
                {roomMess.is_free === 0 && (
                  <Link to={`/detail?id=${roomMess.course_id}`}>查看课程</Link>
                )}
                {roomMess.is_prepare && <span>直播尚未开始，敬请期待...</span>}
              </div>
            </div>
            <div className="live-room-close">
              <i
                onClick={this.liveColse}
                className={"iconfont iconiconfront-2 close"}
              ></i>
            </div>
          </div>
        )}
        {this.state.toSubscribe && (
          <div className="subscribe-box">
            {this.state.step === 0 && (
              <div>
                <div
                  className="close-subscribe"
                  onClick={this.subscribeColse}
                ></div>
                <div className="subscribe-content">
                  <span className="title">请选择即将直播时通知您的方式</span>
                  <button
                    className={
                      this.state.isfollow === 1 ? "follow" : "nofollow"
                    }
                    onClick={this.serverStep}
                  >
                    微信服务号通知
                  </button>
                  {this.state.isfollow === 2 && (
                    <span className="tips">关注服务号即代表预约成功</span>
                  )}
                  <button
                    className={
                      this.state.isfollow === 1 ? "follow" : "nofollow"
                    }
                    onClick={this.iphoneStep}
                  >
                    &nbsp;手机短信通知&nbsp;&nbsp;
                  </button>
                </div>
              </div>
            )}
            {this.state.step === 1 && (
              <div>
                <div
                  className="close-subscribe"
                  onClick={this.subscribeColse}
                ></div>
                <div className="iphone-content">
                  <span className="title">预约</span>
                  <p className="dec">
                    直播前会通过手机短信通知您，您可通过预留的手机号快捷登录参与学习：
                  </p>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    value={this.state.iphone}
                    maxLength={11}
                    onChange={this.handleChange.bind(this)}
                  />
                  <button className="submit" onClick={this.submit}>
                    预约
                  </button>
                </div>
              </div>
            )}
            {this.state.step === 2 && (
              <div>
                <div
                  className="close-subscribe"
                  onClick={this.subscribeColse}
                ></div>
                <div className="iphone-content server-content">
                  <p className="title">
                    <i className={"iconfont icondanseshixintubiao-5"}></i>
                    <span>预约成功</span>
                  </p>
                  <p className="dec">
                    直播开始前会通过微信“服务通知”提醒您。
                    推荐预留手机号，届时可通过手机号登录直播 间直接学习：
                  </p>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    value={this.state.iphone}
                    maxLength={11}
                    onChange={this.handleChange.bind(this)}
                  />
                  <button className="submit" onClick={this.submit}>
                    提交
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {/*{*/}
        {/*this.state.success &&*/}
        {/*<div className='success'>预约成功</div>*/}
        {/*}*/}
      </div>
    )
  }
}

export default LiveRoom
