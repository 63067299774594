/* global wx */
import { getWXObject, getSignature } from "./base"

const shareApiList = [
  "updateAppMessageShareData",
  "updateTimelineShareData",
  "onMenuShareAppMessage",
  "onMenuShareTimeline",
]

export default async function (
  data = { title: "", desc: "", link: "", imgUrl: "" },
  config = {}
) {
  await getWXObject()
  await getSignature(config)

  wx.ready(function () {
    console.log("ready")
    shareApiList.forEach((item) => {
      wx[item](data)
    })
  })
}
