import React, { Component } from "react"
import CallApp from "callapp-lib"

const options = {
  scheme: {
    protocol: "julyedu",
    host: "",
    port: "",
  },
  intent: {
    package: "com.julyapp.julyonline",
    scheme: "julyedu",
    action: "julyapp.julyedu",
    category: "category_julyedu",
  },
  universal: {
    host: "api.julyedu.com/action",
    pathKey: "page",
  },
  appstore: "https://itunes.apple.com/cn/app/id1102275343?mt=8",
  yingyongbao:
    "http://android.myapp.com/myapp/detail.htm?apkName=com.julyapp.julyonline",
  fallback:
    "http://android.myapp.com/myapp/detail.htm?apkName=com.julyapp.julyonline",
}

class OpenApp extends Component {
  callApp = new CallApp(options)
  // callApp = null

  static defaultProps = {
    text: "在APP打开",
  }

  handleClick = () => {
    this.callApp.open({
      path: "",
      param: {},
      callback: () => {
        window.location.href =
          "http://android.myapp.com/myapp/detail.htm?apkName=com.julyapp.julyonline"
      },
    })
  }

  render() {
    return (
      <div className={this.props.className} onClick={this.handleClick}>
        {this.props.text}
      </div>
    )
  }
}

export default OpenApp
