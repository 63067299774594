import aes from "crypto-js/aes"
import Crypto from "crypto-js"

const key = Crypto.enc.Hex.parse(
  "C7D590D00FA968A261BDD5B6CD40DDC2C0561338BF8B9197"
)
const iv = Crypto.enc.Hex.parse("19513F90B7A8875E469E82195F90EE99")

function encrypt(message) {
  return aes
    .encrypt(message, key, {
      iv,
      mode: Crypto.mode.CBC,
    })
    .toString()
}

function decrypt(encrypted) {
  return Crypto.enc.Utf8.stringify(aes.decrypt(encrypted, key, { iv }))
}

export { encrypt, decrypt }
