import React from "react"
import "./orderlist.scss"

/**
 * @OrderList        组件内容
 * @param tab        左侧内容
 * @param info       右侧信息
 * @param children   内容
 * @param restProps  传入的自定义属性
 * @returns {*}
 * @constructor
 */

const OrderItem = ({
  info,
  tab,
  children,
  src,
  id,
  isaist,
  toDetail,
  ...restProps
}) => {
  return (
    <div className="public-list-item">
      <div className="public-content">
        {tab}
        <div className="public-cover">
          {/* <Link to={`/detail?id=${id}`}> */}
          <img src={src} alt="" onClick={() => toDetail(id)} />
          {/* </Link> */}
          {isaist && <span className="return_cash"></span>}
        </div>
        {info}
      </div>
      {React.Children.map(children, (child) =>
        child ? React.cloneElement(child, {}) : child
      )}
    </div>
  )
}

export default OrderItem
