import React, { PureComponent } from "react"
import { browser } from "src/utils"
import "./loginWays.scss"
import more from "../../icons/more.png"

class LoginWays extends PureComponent {
  state = {
    ways: browser.isWeixin
      ? this.props.loginWays.slice(0, 2)
      : this.props.loginWays.slice(0, 1),
    showMore: true,
  }

  handleClick = (text) => {
    this.props.onClick(text)
  }

  filterWays = () => {
    return browser.isWeixin
      ? this.props.loginWays
      : this.props.loginWays.filter((item) => item.id !== "wechat")
  }

  showMore = () => {
    this.setState({
      ways: this.filterWays(),
      showMore: false,
    })
  }

  render() {
    return (
      <div className="login-ways">
        <div className="bottom-title">其他登录方式</div>
        <ul className="login-ways-container">
          {this.state.ways.map((item, index) => {
            return (
              <li key={index} onClick={this.handleClick.bind(this, item.text)}>
                <img src={item.logo} alt={item.text} />
                <p>{item.text}</p>
              </li>
            )
          })}
          {this.state.showMore && (
            <li className={"more"} onClick={this.showMore}>
              <img src={more} alt="更多" />
              <p>更多</p>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default LoginWays
