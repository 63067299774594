import loadable from "@loadable/component"

import Index from "src/components/Index"
import My from "src/components/my"
import Passport from "src/components/passport"
import Scholarship from "src/components/scholarship"

const Coupons = loadable(() =>
  import(/* webpackChunkName: 'coupons'*/ "src/components/coupons")
)
const Study = loadable(() =>
  import(/* webpackChunkName: 'study'*/ "src/components/study")
)
const Video = loadable(() =>
  import(/* webpackChunkName: 'video'*/ "src/components/video")
)
const sharePoster = loadable(() =>
  import(/* webpackChunkName: 'shareposter'*/ "src/components/sharePoster")
)
const myOrders = loadable(() =>
  import(/* webpackChunkName: 'myorders'*/ "src/components/myOrders")
)
const Purchased = loadable(() =>
  import(/* webpackChunkName: 'purchased'*/ "src/components/purchased")
)
const ToGroup = loadable(() =>
  import(/* webpackChunkName: 'togroup'*/ "src/components/detail/group/togroup")
)
const PayOrder = loadable(() =>
  import(
    /* webpackChunkName: 'PayOrder'*/ "src/components/order/payOrder/PayOrder"
  )
)
const DrawDocument = loadable(() =>
  import(
    /* webpackChunkName: 'DrawDocument'*/ "src/components/scholarship/DrawDocument/DrawDocument"
  )
)
const BargainMiddlePage = loadable(() =>
  import(
    /* webpackChunkName: 'BargainMiddlePage'*/ "src/components/bargainMiddlePage"
  )
)
const ShopCart = loadable(() =>
  import(/* webpackChunkName: 'ShopCart'*/ "src/components/shopCart")
)
const Examination = loadable(() =>
  import(/* webpackChunkName: 'Examination'*/ "src/components/examination")
)
const Detail = loadable(() =>
  import(/* webpackChunkName: 'detail'*/ "src/components/detail/index")
)
const SearchResult = loadable(() =>
  import(
    /* webpackChunkName: 'SearchResult'*/ "src/components/search/search-result"
  )
)
const Search = loadable(() =>
  import(/* webpackChunkName: 'search'*/ "src/components/search/index")
)
const Preferential = loadable(() =>
  import(
    /* webpackChunkName: 'Preferential'*/ "src/components/preferential/index"
  )
)
const Orderinfo = loadable(() =>
  import(/* webpackChunkName: 'Orderinfo'*/ "src/components/order/orderinfo")
)
const Order = loadable(() =>
  import(/* webpackChunkName: 'Order'*/ "src/components/order/index")
)
const CourseList = loadable(() =>
  import(
    /* webpackChunkName: 'CourseList'*/ "src/components/classify/courselist"
  )
)
const MyEdit = loadable(() =>
  import(/* webpackChunkName: 'MyEdit'*/ "src/components/my/edit")
)
const Classify = loadable(() =>
  import(/* webpackChunkName: 'Classify'*/ "src/components/classify")
)
const CampTest = loadable(() =>
  import(/* webpackChunkName: 'Classify'*/ "src/components/video/camp/campTest")
)
const CampResolve = loadable(() =>
  import(
    /* webpackChunkName: 'Classify'*/ "src/components/video/camp/campResolve"
  )
)
const ShareCourse = loadable(() =>
  import(
    /* webpackChunkName: 'ShareCourse'*/ "src/components/ShareCourse/index"
  )
)
const Country = loadable(() =>
  import(/* webpackChunkName: 'Country' */ "src/components/country/index")
)
const ExpandCallback = loadable(() => import("src/components/expand/callback"))
const ExpandShare = loadable(() => import("src/components/expand/share"))
const Activity = loadable(() =>
  import(
    /* webpackChunkName: 'Activity' */ "src/components/activity/1111/index"
  )
)
const Invite = loadable(() =>
  import(/* webpackChunkName: 'Invite' */ "src/components/activity/1111/invite")
)
const NewVip = loadable(() =>
  import(/* webpackChunkName: 'Invite' */ "src/components/vip/index")
)
const userAgreement = loadable(() =>
  import(
    /* webpackChunkName: 'Invite' */ "src/components/passport/userAgreement"
  )
)
export default [
  {
    path: "/",
    exact: true,
    component: Index,
  },
  {
    path: "/vip/newvip",
    component: NewVip,
  },
  {
    path: "/classify",
    component: Classify,
  },
  {
    path: "/study",
    component: Study,
  },
  {
    path: "/my",
    component: My,
  },
  {
    path: "/myedit",
    component: MyEdit,
    isPrivate: true,
  },
  {
    path: "/courselist",
    component: CourseList,
  },
  {
    path: "/preferential",
    component: Preferential,
  },
  {
    path: "/search",
    exact: true,
    component: Search,
  },
  {
    path: "/search-result",
    component: SearchResult,
  },
  {
    path: "/order",
    component: Order,
    isPrivate: true,
  },
  {
    path: "/orderinfo",
    component: Orderinfo,
  },
  {
    path: "/detail",
    component: Detail,
  },
  {
    path: "/getDetail",
    component: Detail,
  },
  {
    path: "/examination",
    component: Examination,
  },
  {
    path: "/coupons",
    component: Coupons,
    isPrivate: true,
  },
  {
    path: "/shopcart",
    component: ShopCart,
    isPrivate: true,
  },
  {
    path: "/bargain-middle-page",
    component: BargainMiddlePage,
  },
  {
    path: "/passport",
    component: Passport,
  },
  {
    path: "/userAgreement",
    component: userAgreement,
  },
  {
    path: "/play",
    component: Video,
    isPrivate: true,
  },
  {
    path: "/scholarship",
    component: Scholarship,
  },
  {
    path: "/document",
    component: DrawDocument,
  },
  {
    path: "/shareposter",
    component: sharePoster,
    isPrivate: true,
  },
  {
    path: "/myorders",
    component: myOrders,
    isPrivate: true,
  },
  {
    path: "/purchased",
    component: Purchased,
    isPrivate: true,
  },
  {
    path: "/payOrder",
    component: PayOrder,
  },
  {
    path: "/campTest",
    component: CampTest,
  },
  {
    path: "/campResolve",
    component: CampResolve,
  },
  // 分享领红包课程列表页
  {
    path: "/ShareCourse",
    component: ShareCourse,
  },

  // 区号
  {
    path: "/country",
    component: Country,
  },
  {
    path: "/togroup",
    component: ToGroup,
  },
  {
    path: "/aist-share",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'aist-share'*/ "src/components/share-page/aist-share"
      )
    ),
  },
  {
    path: "/blessingRank",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'blessing-rank' */ "src/components/blessingRank/index"
      )
    ),
  },
  {
    path: "/blessingPreheat",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'blessing-preheat' */ "src/components/blessingPreheat/index"
      )
    ),
  },
  {
    path: "/blessingGetPrize",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'blessing-getPrize' */ "src/components/blessingGetPrize/index"
      )
    ),
  },
  {
    path: "/prize-winner-list",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'prize-winner-list' */ "src/components/activity/1111/prize-winner-list"
      )
    ),
  },
  //定金订单页面
  {
    path: "/deposit-order",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'deposit-order' */ "src/components/order/deposit/deposit-order"
      )
    ),
  },
  //定金支付页面
  {
    path: "/deposit-pay-order",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'deposit-pay-order' */ "src/components/order/deposit/deposit-pay-order"
      )
    ),
  },
  //尾款支付页面
  {
    path: "/final-deposit-order",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'deposit-pay-order' */ "src/components/order/deposit/final-order"
      )
    ),
  },
  // 定金支付之后
  {
    path: "/expand/callback",
    component: ExpandCallback,
  },
  // 定金-邀请好友助力
  {
    path: "/expand/index",
    component: ExpandShare,
  },
  {
    path: "/toAppDemo",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'aist-share'*/ "src/components/blessingPreheat/toAppDemo"
      )
    ),
  },
  {
    path: "/activity",
    exact: true,
    component: Activity,
  },
  {
    path: "/invite",
    component: Invite,
  },

  //双旦活动
  {
    path: "/activity/newyear-2019/landing",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'newyear-2019-landing'*/ "src/components/activity/newyear-2019/landing/index"
      )
    ),
  },

  // 双旦活动预热页面
  {
    path: "/year/yearIndex",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'newyear-yearIndex' */ "src/components/activity/newyear-2019/preheat/index"
      )
    ),
  },

  // 我的宝箱
  {
    path: "/year/yearTreasure",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'newyear-yearIndex' */ "src/components/activity/newyear-2019/myTreasure/index"
      )
    ),
  },
  // 双旦心愿单
  {
    path: "/year/yearWish",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'newyear-yearIndex' */ "src/components/activity/newyear-2019/year-wish/index"
      )
    ),
  },
  // python 小课页面
  {
    path: "/python",
    component: loadable(() =>
      import(/* webpackChunkName: 'python-class'*/ "src/components/python")
    ),
  },
  {
    path: "/pythonShare",
    component: loadable(() => import("src/components/pythonShare")),
  },
  {
    path: "/pythonStudy",
    component: loadable(() => import("src/components/python/pythonStudy")),
  },
  //  赠一得一
  {
    path: "/active/givecourse",
    component: loadable(() =>
      import(
        /* activity-give-courses */ "src/components/activity/give-courses/index"
      )
    ),
  },
  {
    path: "/active/assistance",
    component: loadable(() =>
      import(
        /* activity-give-courses-assistance */ "src/components/activity/give-courses/assistance/index"
      )
    ),
  },
  {
    path: "/active/to",
    component: loadable(() =>
      import(
        /* activity-give-courses-share */ "src/components/activity/give-courses/share-content/index"
      )
    ),
  },
  {
    path: "/wxerr",
    component: loadable(() =>
      import(/* wx-err */ "src/components/wxerr/index")
    ),
  },

  // 助学计划落地页
  {
    path: "/college",
    exact: true,
    component: loadable(() => import("src/components/college")),
  },
  // 助学计划落地页
  {
    path: "/college/:id",
    exact: true,
    component: loadable(() => import("src/components/college/courseList")),
  },
  //限时免费落地页
  {
    path: "/free",
    exact: true,
    component: loadable(() =>
      import(/*limit-free*/ "src/components/limit-free")
    ),
  },
  // 新的开宝箱活动-活动页
  {
    path: "/box/boxActive",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'treasure-box-home' */ "src/components/activity/treasure-box/preheat/index"
      )
    ),
  },
  // 新的开宝箱活动-我的宝箱
  {
    path: "/box/yearTreasure",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'treasure-box-mybox' */ "src/components/activity/treasure-box/myTreasure/index"
      )
    ),
  },
  // 新的开宝箱活动-扫码、分享页
  {
    path: "/box/landing",
    component: loadable(() =>
      import(
        /* webpackChunkName: 'newyear-2019-landing'*/ "src/components/activity/treasure-box/landing/index"
      )
    ),
  },
  //ML小课
  {
    path: "/ml",
    exact: true,
    component: loadable(() =>
      import(/* webpackChunkName: 'ml' */ "src/components/ml")
    ),
  },
  {
    path: "/mlShare",
    component: loadable(() =>
      import(/* webpackChunkName: 'mlShare' */ "src/components/mlShare")
    ),
  },
  //ai水平测试
  {
    path: "/ai-test",
    component: loadable(() =>
      import(/* webpackChunkName: 'ai-test' */ "src/components/ai-test")
    ),
  },
  //智能选课
  {
    path: "/intelligent-recommend",
    isPrivate: true,
    component: loadable(() =>
      import(
        /* webpackChunkName: 'intelligent-recommend' */ "src/components/intelligent-recommend"
      )
    ),
  },
  //题库
  {
    path: "/problems/:id",
    component: loadable(() => import("src/components/problems")),
  },
  //2020-717
  {
    path: "/anniversary_2020",
    component: loadable(() => import("src/components/activity/2020-717")),
  },

  {
    path: "/ts-test",
    component: loadable(() =>
      import(/* webpackChunkName: 'ts-test' */ "src/components/ts-test")
    ),
  },
  {
    path: "/python-course",
    component: loadable(() =>
      import("src/components/pythonCourse")
    ),
  },
]
