import React, { Component } from "react"
import "./set-password.scss"
import { withFormik, Form, Field } from "formik"
import PasswordInput from "../common/passwordInput"
import Button from "../common/Button"
import classnames from "classnames"
import { compose } from "redux"
import { HeaderBar } from "src/common"
import { http } from "src/utils"
import { Toast } from "antd-mobile"
import { encrypt } from "src/components/passport/encryption"
import { isEmpty } from "lodash"
import { connect } from "react-redux"
import { setCurrentUser } from "src/store/userAction"
import jsCookie from "js-cookie"
import { Link } from "react-router-dom"

class SetPassword extends Component {
  toFrom = () => {
    let historyUrl = window.localStorage.getItem("HistoryUrl")
    const { history } = this.props
    history.push(historyUrl)
  }

  render() {
    let { values, errors, location } = this.props
    let { from } = location.state || { from: { pathname: "/" } }
    return (
      <>
        <HeaderBar arrow={true} title={"设置密码"} />
        <div className={"set-password"}>
          <p className="title">密码需要包含6-16位字母及数字</p>
          <Form>
            <Field name="password">
              {({ field }) => {
                return (
                  <PasswordInput
                    autoComplete={"on"}
                    placeholder={"设置密码"}
                    onChange={this.handleChange}
                    {...field}
                  />
                )
              }}
            </Field>
            <Button
              className={"btn-active"}
              active={values.password && values.agreement && isEmpty(errors)}
            >
              完成
            </Button>
            <label htmlFor="agreement" className="user-agreement">
              <Field
                type="checkbox"
                name="agreement"
                id="agreement"
                className={classnames([
                  this.props.values.agreement
                    ? "iconfont iconRectangleCopy"
                    : "disagree",
                ])}
              />
              同意<Link to="/userAgreement">《七月在线用户使用协议》</Link>
            </label>
          </Form>
          <div
            className="skip"
            style={{
              display:
                from && from.pathname.includes("forgot-password")
                  ? "none"
                  : "block",
            }}
          >
            <span onClick={this.toFrom}>跳过</span>
          </div>
        </div>
      </>
    )
  }
}

const formikConfig = {
  mapPropsToValues() {
    return {
      password: "",
      agreement: true,
    }
  },
  handleSubmit: (values, { props }) => {
    const { location } = props

    let from = (location.state &&
      location.state.records &&
      location.state.records[location.state.records.length - 2]) || {
      pathname: "/",
    }
    if (from.pathname.includes("forgot-password")) {
      forgotPasswordReset(values, props)
    } else {
      bindMobileSetPassword(values, props)
    }
  },
  validateOnChange: false,
  validate: (values) => {
    let errors = {}
    const re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
    if (!re.test(values.password)) {
      errors.password = "密码需要包含6-16位字母及数字"
      Toast.info(errors.password, 2, null, false)
    }
    if (!values.agreement) {
      errors.agreement = "您须同意《七月在线用户使用协议》"
      Toast.info(errors.agreement, 2, null, false)
    }
    return errors
  },
}

function forgotPasswordReset(values, props) {
  let key = sessionStorage.getItem("r_type") === "email" ? "email" : "tel"

  let requestKey = key === "email" ? "email" : "phone"
  http
    .post(`${API["passport-api"]}/account/up_pass_by_${requestKey}`, {
      [requestKey]: sessionStorage.getItem(key),
      password: encrypt(values.password),
    })
    .then((res) => {
      if (res.data.errno === 200) {
        Toast.info("密码设置成功")
        setTimeout(function () {
          props.history.replace("/passport/account-login")
        }, 1000)
      } else {
        Toast.info(res.data.msg, 2, null, false)
      }
    })
}

function bindMobileSetPassword(values, props) {
  let uid = jsCookie.get("uid")
  http
    .post(`${API["passport-api"]}/bind_mobile/set_pwd_new`, {
      uid: props.user.data.uid || uid,
      password: encrypt(values.password),
    })
    .then((res) => {
      if (res.data.errno === 200) {
        const { location, history } = props
        Toast.info("密码设置成功")
        let from = (location.state && location.state.from) || { pathname: "/" }

        let HistoryUrl = window.localStorage.getItem("HistoryUrl")
        setTimeout(() => {
          if (HistoryUrl) {
            let historyUrl = window.localStorage.getItem("HistoryUrl")
            props.history.push(historyUrl)
          } else {
            history.replace(from.pathname)
          }
        }, 1000)
      } else {
        Toast.info(res.data.msg, 2, null, false)
      }
    })
}

export default compose(
  connect((state) => ({ user: state.user }), { setCurrentUser }),
  withFormik(formikConfig)
)(SetPassword)
