export const ADD_MESSAGE = "ADD_MESSAGE"
export const ADD_RESULT = "ADD_RESULT"
export const RESELECT = "RESELECT"

export const addMessage = (payload) => {
  return {
    type: ADD_MESSAGE,
    payload,
  }
}

export const addResult = (payload) => {
  return {
    type: ADD_RESULT,
    payload,
  }
}

export const reselect = () => {
  return {
    type: RESELECT,
  }
}

const initialState = {
  processing: [],
  result: {},
}

export default function intelligentRecommend(state = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        processing: [...state.processing, action.payload],
        result: state.result,
      }
    case ADD_RESULT:
      return {
        processing: state.processing,
        result: action.payload,
      }
    case RESELECT:
      return initialState
    default:
      return state
  }
}
