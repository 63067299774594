import {
  SET_CURRENT_USER,
  UPDATE_USER,
  START_FETCH_USER,
  END_FETCH_USER,
} from "src/store/userAction"
import { merge } from "lodash"

const notLoggedIn = 4030

export const initialState = {
  hasError: true,
  code: notLoggedIn,
  msg: "",
  data: {
    username: "",
    avatar: "",
    isVip: false,
    token: "",
    email: "",
    uid: "",
  },
  isFetching: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.payload
    case UPDATE_USER:
      return merge({}, state, action.payload)
    case START_FETCH_USER:
      return { ...state, isFetching: true }
    case END_FETCH_USER:
      return { ...state, isFetching: false }
    default:
      return state
  }
}
