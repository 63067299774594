import React from "react"
import "./loginButton.scss"
import classnames from "classnames"

const LoginButton = React.memo(({ onClick, active }) => {
  return (
    <button
      type={"submit"}
      onClick={onClick}
      className={classnames("login-button", { active })}
    >
      登录
    </button>
  )
})

export default LoginButton
