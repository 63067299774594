import React from "react"
import "./input.scss"
import classnames from "classnames"

function Input({ icon, wrapperClass, children, value, name, ...rest }) {
  return (
    <div className={classnames("input-wrapper", wrapperClass)}>
      <input className="input" name={name} value={value} {...rest} />
      {icon}
      {children}
    </div>
  )
}

export default Input
