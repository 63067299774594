import React, { PureComponent } from "react"

function setSize() {
  document.body.style.height = `${window.innerHeight}px`
  document.getElementById("root").style.height = `${window.innerHeight}px`
  document.documentElement.style.height = `${window.innerHeight}px`
}

export default function (WrappedComponent) {
  return class extends PureComponent {
    componentDidMount() {
      setSize()
      window.addEventListener("resize", setSize)
    }
    componentWillUnmount() {
      document.body.style.height = `auto`
      document.getElementById("root").style.height = `auto`
      document.documentElement.style.height = "auto"
      window.removeEventListener("resize", setSize)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
