import React, { Component } from "react"
import ReactDOM from "react-dom"
import { HashLoader } from "react-spinners"
import "./loading.scss"

const container = document.body

class Loading extends Component {
  static defaultProps = {
    text: "加载中",
    fake: 0,
  }

  state = {
    isLoading: true,
  }

  componentDidMount() {
    if (!this.props.isLoading) {
      this.setState({
        isLoading: false,
      })
    }
  }

  componentDidUpdate(prevProps) {
    let { isLoading, fake } = this.props
    if (!isLoading) {
      if (fake) {
        setTimeout(() => {
          this.setState({
            isLoading,
          })
        }, fake)
      } else {
        if (prevProps.isLoading) {
          this.setState(() => ({
            isLoading: false,
          }))
        }
      }
    } else {
      if (prevProps.isLoading !== isLoading) {
        this.setState(() => ({
          isLoading: true,
        }))
      }
    }
  }

  render() {
    const innerLoading = (
      <div className="loading">
        <div className="loading-wrapper">
          <HashLoader
            css={{
              display: "block",
              marginTop: "-100px",
            }}
            size={50}
            color={"#09f"}
          />
          <p>{this.props.text}</p>
        </div>
      </div>
    )

    return this.state.isLoading
      ? ReactDOM.createPortal(innerLoading, container)
      : this.props.children
  }
}

export default Loading
