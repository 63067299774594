import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import "./passport.scss"
import { WithFullSize } from "src/HOCs"
import Login from "./login"
import AccountLogin from "./accountLogin"
import ForgotPassword from "./forgotPassword"
import SetPassword from "./setPassword"
import BindingTel from "./bindingTel"
import ForgotPasswordEmail from "./forgotPasswordEmail"
import { connect } from "react-redux"
import { compose } from "redux"
import StudentRoot from "./studentRoot"

import account from "./icons/account.png"
import qq from "./icons/qq.png"
import sina from "./icons/sina.png"
import wechat from "./icons/wechat.png"

class Passport extends Component {
  redirectURL = "/"
  count = 1
  blackList = new Set(["/passport/binding-tel", "/passport/set-password"])

  constructor(props) {
    super(props)
    const { location } = props
    const { pathname, search, hash } = location
    const from = (location.state && location.state.from) || {
      pathname: pathname,
      search: search,
      hash: hash,
    }
    const referrer = document.referrer
    this.redirectURL = referrer
      ? referrer
      : window.location.origin + from.pathname + from.search + from.hash
    this.state = {
      loginWays: [
        {
          logo: account,
          text: "账号登录",
          id: "account",
        },
        {
          logo: wechat,
          text: "微信",
          url: "",
          id: "wechat",
        },
        {
          logo: qq,
          text: "QQ",
          url: `${
            API["passport-api"]
          }/m/login/qqLogin?redirect_url=${encodeURIComponent(
            this.redirectURL
          )}`,
          id: "qq",
        },
        {
          logo: sina,
          text: "新浪",
          url: `${
            API["passport-api"]
          }/m/login/sinaLogin?redirect_url=${encodeURIComponent(
            this.redirectURL
          )}`,
          id: "sina",
        },
      ],
    }
  }

  componentDidMount() {
    const { history } = this.props
    if (!window.passportHistoryListener) {
      this.unlisten = history.listen((location, action) => {
        window.passportHistoryListener = this.unlisten

        location.pathname !== "/country" &&
          !location.pathname.startsWith("/passport") &&
          this.unlisten()

        if (action === "PUSH") {
          this.count++
        } else if (action === "POP") {
          this.count--
        }
      })
    }
  }

  componentDidUpdate() {
    this.routeWhenUserLoggedIn()
  }

  routeWhenUserLoggedIn = () => {
    let { hasError } = this.props.user
    if (!hasError && !this.blackList.has(this.props.location.pathname)) {
      const redirectURI = new URLSearchParams(window.location.search).get(
        "redirect"
      )
      redirectURI
        ? (window.location.href = decodeURIComponent(redirectURI))
        : this.props.history.go(-this.count)
    }
  }

  render() {
    let { match, location } = this.props
    return (
      <div className="passport">
        <Switch>
          <Redirect
            exact
            from={"/passport"}
            to={{ ...location, ...{ pathname: "/passport/login" } }}
          />
          <Route path={match.url + "/student-login"} component={StudentRoot} />
          <Route
            path={match.url + "/login"}
            render={(props) => {
              return <Login {...props} loginWays={this.state.loginWays} />
            }}
          />
          <Route path={match.url + "/account-login"} component={AccountLogin} />
          <Route
            path={match.url + "/forgot-password"}
            component={ForgotPassword}
          />
          <Route
            path={match.url + "/forgot-password-email"}
            component={ForgotPasswordEmail}
          />
          <Route
            path={match.url + "/set-password"}
            render={(props) => {
              return <SetPassword {...props} count={this.count} />
            }}
          />
          <Route path={match.url + "/binding-tel"} component={BindingTel} />
        </Switch>
      </div>
    )
  }
}

export default compose(
  connect((state) => ({ user: state.user }), null),
  WithFullSize
)(Passport)
