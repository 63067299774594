import React from "react"
import { Link, withRouter } from "react-router-dom"
import { Course } from "src/common/index"
import "./index.scss"

// 课程模块儿公共组件
// 课程数量是奇数第一个课程需要横着展示沾满一行，课程数量是偶数一行显示两个

const HomeCourseList = function ({ modules, history }) {
  const toDetail = (id) => {
    history.push(`/detail?id=${id}`)
  }

  return (
    modules.length > 0 &&
    modules.map((module, i) => {
      return (
        <div key={i}>
          <Module module={module} toDetail={toDetail} />
          <p className="borderTop" />
        </div>
      )
    })
  )
}

const Module = function CourseList({ module, toDetail }) {
  let filterList = []
  let isOdd = module.list.length % 2 === 0

  if (module.name === "限时免费") {
    filterList = module.list
  } else {
    // 数量为奇数时，第一个课程显示大图（如后台未上传，前台显示小图），课程数量为偶数时，均显示小图

    if (isOdd) {
      filterList = module.list
    } else {
      filterList =
        module.list[0].course_img === module.list[0].course_img_small
          ? module.list
          : module.list.slice(1)
    }
  }

  return (
    <div className="category home-course-list">
      {module.name !== "双11秒杀课" ? (
        <h2 className="title">{module.name}</h2>
      ) : (
        <h2 className="bq">
          <img className="hot2" src={require("./../image/11.png")} alt="" />
        </h2>
      )}
      {module.name === "限时免费" && <span className={"hot"}>hot</span>}

      {module.show_more === 1 && (
        <Link className="more" to="/classify">
          查看更多 &gt;
        </Link>
      )}

      {module.show_more === 2 ? (
        module.name === "限时免费" ? (
          <Link className="more" to={"/free"}>
            查看更多 &gt;
          </Link>
        ) : (
          <Link className="more" to={module.more_page}>
            查看更多 &gt;
          </Link>
        )
      ) : null}
      {module.name === "初级入门课" ? (
        <ul className="index-course-detail">
          {module.name !== "限时免费" &&
            !isOdd &&
            module.list[0].course_img !== module.list[0].course_img_small && (
              <div
                className="category-vip"
                onClick={() => toDetail(module.list[0].course_id)}
              >
                <img src={module.list[0].course_img} alt="" />
              </div>
            )}
          {filterList.map((item, index) => {
            const top = item.is_limit_free ? null : (
              <div>
                {item.is_audition === true && (
                  <span className="audition">
                    <i className={"iconfont iconerji"}></i>试听
                  </span>
                )}
                {item.is_aist && <span className="return_bash"></span>}
              </div>
            )

            const bottom = <Bottom course={item} course1={module} />

            const status = item.is_limit_free ? null : (
              <div>
                {item.is_bargain && (
                  <p className="course-status">砍价减{item.bargain_price}元</p>
                )}
                {item.is_groupon && (
                  <p className="course-status">拼团价{item.groupon_price}元</p>
                )}
              </div>
            )
            return (
              <Course
                key={index}
                top={top}
                data={item}
                bottom={bottom}
                status={status}
                img={item.course_img_small}
                title={item.course_title}
                id={item.course_id}
                toDetail={toDetail}
                className="text-overflow-2"
              />
            )
          })}
          <div className="vip">
            <Link to={"/vip/newvip"}>
              <img
                src="https://julyedu-img.oss-cn-beijing.aliyuncs.com/huiyuanganggaowei.png"
                alt=""
              />
            </Link>
            <a
              href="https://live.easyliao.com/live/chat.do?c=27526&g=53481&config=81372"
              className="consult"
              target="_blank"
            >
              <img
                className="govip"
                src={require("./../image/kt.png")}
                alt=""
              />
            </a>
          </div>
        </ul>
      ) : (
        <ul className="index-course-detail">
          {module.name !== "限时免费" &&
            !isOdd &&
            module.list[0].course_img !== module.list[0].course_img_small && (
              <div
                className="category-vip"
                onClick={() => toDetail(module.list[0].course_id)}
              >
                <img src={module.list[0].course_img} alt="" />
              </div>
            )}
          {filterList.map((item, index) => {
            const top = item.is_limit_free ? null : (
              <div>
                {item.is_audition === true && (
                  <span className="audition">
                    <i className={"iconfont iconerji"}></i>试听
                  </span>
                )}
                {item.is_aist && <span className="return_bash"></span>}
              </div>
            )

            const bottom = <Bottom course={item} course1={module} />

            const status = item.is_limit_free ? null : (
              <div>
                {item.is_bargain && (
                  <p className="course-status">砍价减{item.bargain_price}元</p>
                )}
                {item.is_groupon && (
                  <p className="course-status">拼团价{item.groupon_price}元</p>
                )}
              </div>
            )
            return (
              <Course
                key={index}
                top={top}
                data={item}
                bottom={bottom}
                status={status}
                img={item.course_img_small}
                title={item.course_title}
                id={item.course_id}
                toDetail={toDetail}
                className="text-overflow-2"
              />
            )
          })}
        </ul>
      )}
      {/* <LazyLoad offset={50}> */}

      {/* </LazyLoad> */}
    </div>
  )
}
//限时免费
function LimitFree({ course }) {
  /*
   *
   * limit_free_status: 0-未领取 1-已领取 2-已过期
   *
   * */
  switch (course.limit_free_status) {
    case 0:
      return (
        <Link to={`/detail?id=${course.course_id}`}>
          <p className={"course-price"}>
            <span className={"free"}>免费领取</span>
            <span className={"old"}>¥{course.price}</span>
          </p>
        </Link>
      )
    case 1:
      return <div className={"isbuy"}>已领取</div>
    default:
      return (
        <p className="course-price">
          <span className="new">¥{course.discounts_price}</span>
          <span className="old">¥{course.price}</span>
        </p>
      )
  }
}

//课程底部
function Bottom({ course, course1 }) {
  if (course.is_buy) {
    if (course.is_limit_free && course.limit_free_status === 1) {
      return <div className={"isbuy"}>已领取</div>
    } else {
      return <div className={"isbuy"}>已购买</div>
    }
  } else {
    return course.is_limit_free ? (
      <LimitFree course={course} />
    ) : (
      <div>
        {course1.name === "1分起购课" ? (
          <p className="course-price">
            <span className="new">特惠价：¥{course.discounts_price}</span>
            <span className="old">¥{course.price}</span>
          </p>
        ) : (
          <p className="course-price">
            <span className="new">¥{course.discounts_price}</span>
            <span className="old">¥{course.price}</span>
          </p>
        )}
      </div>
    )
  }
}

export default withRouter(HomeCourseList)
