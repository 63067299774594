/* eslint-disable eqeqeq */
import React, { Component } from "react"
import "./index.scss"
import { WithTab } from "src/HOCs"
// import Swiper from 'react-mobile-swiper'
// import createStyle from './createStyle'
import HomeCourseList from "./course-list"
import { http, isValidUrl } from "src/utils"
import LiveRoom from "./liveRoom"
import { Link } from "react-router-dom"
import { Toast } from "antd-mobile"
import { connect } from "react-redux"
import TopSwiper from "./TopSwiper"
// import ExpandActiveToast from './expandActiveToast'
import TeacherList from "./tercher"
import Teaching from "./teaching"

//tan框
import UserGift from "src/common/UserGift"

// const animateTypes = Swiper.animateTypes

@connect((state) => ({
  user: state.user,
}))
class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      banner: [], // 首页banner
      lives: [], //近期直播
      modules: [], //首页课程模块儿
      isShow: false,
      islive: false,
      roomMess: "",
      isShowUserGift: false,
      tabdata: [
        {
          src: require("./image/freeclass_icon.png"),
          name: "免费课程",
          href: "/study/free-course",
        },
        {
          src: require("./image/zjxj_icon.png"),
          name: "赚奖学金",
          href: "/scholarship",
        },
        {
          src: require("./image/mryt_icon.png"),
          name: "高频试题",
          href: "/examination",
        },
        {
          src: require("./image/shequ_icon.png"),
          name: "社区",
          href: "https://ask.julyedu.com",
        },
      ],
    }
  }

  componentDidMount() {
    this.getIndexData()
    this.userStatus()
  }

  componentWillMount() {
    this.showUserGiftFun()
  }

  userStatus = () => {
    http.get(`${API["base-api"]}/sys/user/new_user_status`).then((res) => {
      const { errno, data } = res.data
      if (errno === 0) {
        if (data.status == 1) {
          // 新用户登录之后判断是否是新用户
          this.setState({
            isShowUserGift: false,
          })
          Toast.info("新人大礼包已领取成功！", 2)
        } else {
          this.setState({
            isShowUserGift: false,
          })
        }
      }
    })
  }

  showUserGiftFun = () => {
    let newer_last_time = localStorage.getItem("newer_last_time") // 获取用户关闭大礼包的时间
    let now_time = new Date().valueOf()
    if (!newer_last_time || now_time - newer_last_time > 86400000) {
      this.setState({
        isShowUserGift: true,
      })
    } else {
      this.setState({
        isShowUserGift: false,
      })
    }
  }
  close = () => {
    this.setState({
      isShowUserGift: false,
    })
    let now_time = new Date().valueOf() // 获取当前时间
    localStorage.setItem("newer_last_time", now_time) // 存储关闭时间
  }

  get_newerModal = () => {
    this.close()
    this.props.history.push("/passport/login")
  }

  // 首页课程
  getIndexData = () => {
    http.get(`${API.home}/m/home`).then((res) => {
      if (res.data.code === 200) {
        const { data } = res.data || {}
        const modules = Array.isArray(data.modules) ? data.modules : []
        this.setState({
          banner: data.banner,
          lives: data.lives,
          modules,
        })
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }

  // 点击近期直播课程弹出预约提示框
  liveCourse = (item) => {
    const { user } = this.props
    const uid = user && user.data && user.data.uid
    if (!uid) {
      this.props.history.push("/passport/login")
    } else {
      if (item.live_status === 0) {
        this.setState({
          isShow: true,
          islive: true,
          roomMess: item,
        })
      } else {
        window.location.href = `${
          window.location.href.includes("pre")
            ? "http://www-pre.julyedu.com"
            : "http://www.julyedu.com"
        }/live/m_room/${item.room_id}`
      }
    }
  }
  govip = () => {}
  // 自组件传给父组件的isshow
  colseBox = (val) => {
    this.setState({ isShow: val })
  }

  // 点击头部搜索跳转到搜索页面
  toSearch() {
    this.props.history.push("/search")
  }

  render() {
    return (
      <div className="index-box">
        <div className="header">
          <img
            className="logo"
            src="http://img-public.julyedu.com/Public/img/index/logo.png"
            alt=""
          />
          {/* <CallApp
            className='to-app'
            text='在APP打开'
          />*/}
          <div className="right">
            {/* <Link to={"/intelligent-recommend"}>
              <span className={"intelligent-recommend-entry"}>
                <i className={"mind-icon"}></i>
                智能选课
              </span>
            </Link> */}
            <i
              className="iconfont iconiconfront- search"
              onClick={this.toSearch.bind(this)}
            />
          </div>
        </div>

        <div className="zw_height"></div>

        {/*支付尾款提示*/}
        {/*<ExpandActiveToast/>*/}

        <div className="index-swiper">
          {this.state.banner && this.state.banner.length > 0 && (
            <TopSwiper bannerList={this.state.banner} />
          )}
        </div>

        <div className="tabbox">
          <ul>
            {this.state.tabdata.map((item, index) => {
              return (
                <li key={index}>
                  {isValidUrl(item.href) ? (
                    <a href={item.href}>
                      <img src={item.src} alt="" />
                      <span>{item.name}</span>
                    </a>
                  ) : (
                    <Link to={item.href}>
                      <img src={item.src} alt="" />
                      <span>{item.name}</span>
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </div>

        <p className="borderTop" />

        {this.state.lives && this.state.lives.length > 0 ? (
          <div className="lives">
            <h2 className="title">近期直播</h2>
            <ScrollBox
              livesList={this.state.lives}
              liveCourse={this.liveCourse}
            />
          </div>
        ) : null}

        <HomeCourseList modules={this.state.modules} />
        <TeacherList />
        <p className="borderTop" />
        {/* <botton className="fix"><a href="https://live.easyliao.com/live/chat.do?c=27526&g=53481&config=81372"><span>点击</span><span>咨询</span></a></botton> */}
        <Teaching />

        {/* <AllCourseNavigation /> */}

        {/* 直播间预约 */}
        {this.state.islive && (
          <LiveRoom
            isShow={this.state.isShow}
            colseBox={this.colseBox}
            roomMess={this.state.roomMess}
            getIndexData={this.getIndexData}
          />
        )}

        {this.state.isShowUserGift && (
          <UserGift close={this.close} get_newerModal={this.get_newerModal} />
        )}
      </div>
    )
  }
}

//近期直播
function ScrollBox(props) {
  return (
    <div className="scroll-box">
      <ul className="scroll-list">
        {props.livesList &&
          props.livesList.length > 0 &&
          props.livesList.map((item, index) => {
            return (
              <li
                key={index}
                className="scroll-item"
                onClick={(e) => props.liveCourse(item)}
              >
                <div className="item-box">
                  {item.live_status === 0 && (
                    <span className="no-start">即将开始</span>
                  )}
                  {(item.live_status === 1 || item.live_status === 10) && (
                    <span className="start">正在直播</span>
                  )}
                  <img className="item-img" src={item.live_img} alt="" />
                  <div className="item-content">
                    <h2 className="item-title">{item.live_title}</h2>
                    <p className="item-teacher">
                      讲师：{item.live_teacher_name}
                    </p>

                    {/*公开课需预约、付费课不需要预约*/}
                    {(item.is_prepare || item.is_free === 0) &&
                      item.live_status === 0 && (
                        <p className="item-time">
                          时间：{item.live_start_time}
                        </p>
                      )}
                    {!item.is_prepare &&
                      item.live_status === 0 &&
                      item.is_free === 1 && (
                        <button className="item-btn">预约</button>
                      )}
                    {(item.live_status === 1 || item.live_status === 10) && (
                      <button className="item-btn">正在直播</button>
                    )}
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default WithTab(Index)
