import React from "react"
import { Switch, Route } from "react-router-dom"
import RouterConfig from "./router-config"
import PrivateRoute from "./privateRoute"

export default function () {
  return (
    <Switch>
      {RouterConfig.map((item, index) => {
        let { isPrivate, ...rest } = item
        if (isPrivate) {
          return <PrivateRoute {...rest} key={index} />
        } else {
          const { component: Component, ...rest } = item
          return (
            <Route
              {...rest}
              key={index}
              render={(props) => {
                return <Component {...props} />
              }}
            />
          )
        }
      })}
    </Switch>
  )
}
