import React from "react"
import "./index.scss"

const VList = (props) => {
  return (
    <li
      className="v-list-item"
      onClick={() => {
        if (typeof props.toDetail !== "function") {
          return
        }
        if (props.needCourseInfo) {
          props.toDetail(props.course)
        } else {
          props.toDetail(props.id)
        }
      }}
    >
      <div className="content">
        <div className="cover">
          {props.status}
          {props.courseExpire}
          {props.toDetail ? (
            <img src={props.img} alt="" />
          ) : (
            <img src={props.img} alt="" />
          )}
        </div>
        {props.info}
      </div>
      {props.tab}
    </li>
  )
}

export default VList
