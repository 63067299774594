import React, { Component } from "react"
import "./../index.scss"
import { http } from "src/utils"
import { Link } from "react-router-dom"
import { Toast } from "antd-mobile"

class Teaching extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data:[],
    }
  }

  componentDidMount() {
    http.get(`${API.home}/m/mobTeachers`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data:res.data.data
        });
        console.log(this.state.data)
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }


  render() {
    return (
      <div className="teaching"><p className="cont">360°教学&就业服务</p>

        <div className="scroll-box2">
          
          <ul className="scroll-list">
            <li className="scroll-item">
              <div>
              <h2>内容覆盖</h2>
              <p>本科4年</p>
              <p>研究生3年</p>
              <p>传统IT转型AI</p>
              <p>在职提升</p>
              </div>
            </li>
            <li className="scroll-item">
              <div>
              <h2>40%学生</h2>
              <p><span>大专</span>
              <span>本科</span>
              <span>硕士</span>
              <span>博士</span></p>
              <h2>60%在职</h2>
              <p><span>BAT</span><span>美团</span></p>
              <p>华为等大中小公司职员</p>
              </div>
            </li>
            <li className="scroll-item">
              <div>
              <h2>完整教学服务 教学测练评</h2>
              <p><span>入学测评</span><span>直播答疑</span></p>
              <p><span>布置作业</span><span>阶段考试</span></p>
              <p><span>毕业考核</span><span>一对一批改</span></p>
              </div>
            </li>
            <li  className="scroll-item">
              <div>
              <h2>强大的就业服务</h2>
              <p>简历/面试辅导</p>
              <p>1V1内推大厂</p>
              <p>大部分就业班三个月内就 业率实现100%，平均年薪近40万</p>
              </div>
            </li>
            <li className="scroll-item">
              <div>
                <h2>CPU和GPU双云实验平台</h2>
              <p>底层封装Tensorflow、Pytorch、Keras等库</p>
              <p>真枪实战，拒绝纸上谈兵</p>
              </div>
              
            </li>
          </ul>
        </div>
        </div>
    )
  }
}

export default Teaching



