import { useEffect } from "react"
import { connect } from "formik"

const OnSubmissionError = (props) => {
  const { callback, formik } = props

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isValid && !formik.isSubmitting) {
      callback(formik)
    }
  }
  useEffect(effect, [formik.submitCount, formik.isSubmitting])
  return null
}

export default connect(OnSubmissionError)
