import React, { Component } from "react"
import "./index.scss"

class WxLogin extends Component {
  // 提示微信登录还是账号登录，微信授权登录不需要绑定手机号
  wxLogin = () => {
    let url = window.location.href
    if (url.includes("code=") && url.includes("state=STATE")) {
      let index = url.lastIndexOf("code=")
      url = url.substr(0, index - 1)
    }
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx23dac6775ac82877&redirect_uri=" +
      encodeURIComponent(url + "&aa=bb").toLowerCase() +
      "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
  }
  zhLogin = () => {
    this.props.history.push("/passport")
  }

  render() {
    return (
      <div className="change-login-type">
        <div className="login-type-content">
          <div className="wx-login" onClick={this.wxLogin}>
            <img src="https://cdn.julyedu.com/mlCourse/m/wx-icon.png" alt="" />
            <span>微信登录</span>
          </div>
          <div className="zh-login" onClick={this.zhLogin}>
            <img src="https://cdn.julyedu.com/mlCourse/m/zh-icon.png" alt="" />
            <span>账号登录</span>
          </div>
        </div>
      </div>
    )
  }
}

export default WxLogin
