import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import classnames from "classnames"

const re = /(https?|ftp):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/

interface Props {
  title: string
  content: React.ReactNode
  className?: string
  closable?: boolean
  clickMaskClose?: boolean
  close?: () => void | Promise<void>
  closeIcon?: string
  remove?: () => void
  afterClose?: () => void
}

function ClosablePopup({
  title,
  content,
  className,
  closable = true,
  close = function () {},
  clickMaskClose = true,
  closeIcon = "iconiconfront-2",
  afterClose = function () {},
  remove = function () {},
}: Props) {
  function unmountComponent() {
    ReactDOM.unmountComponentAtNode(div)
    if (div && div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  function _close() {
    let _c = close()
    if (_c && _c.then) {
      _c.then(() => {
        unmountComponent()
        afterClose()
      })
    } else {
      unmountComponent()
      afterClose()
    }
  }

  function clickMask() {
    if (closable) {
      return
    }
    if (!clickMaskClose) {
      return
    }
    _close()
  }

  const closablePopup = (
    <div className={"closable-popup-mask"} onClick={clickMask}>
      <div className={classnames(["popup-container", className])}>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        {closable &&
          (re.test(closeIcon) ? (
            <img
              src={closeIcon}
              alt=""
              className={"close-icon"}
              onClick={_close}
            />
          ) : (
            <i className={`close iconfont ${closeIcon}`} onClick={_close} />
          ))}
      </div>
    </div>
  )
  const div = document.createElement("div")
  document.body.appendChild(div)

  ReactDOM.render(closablePopup, div)

  return {
    close: _close,
    remove: unmountComponent,
  }
}

export default ClosablePopup
