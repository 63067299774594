import React, { Component } from "react"
import {
  Tabs,
  WhiteSpace,
  List,
  Flex,
  WingBlank,
  Modal,
  ListView,
  Toast,
} from "antd-mobile"
import "./scholarship.scss"
import { browser, http } from "src/utils"
import CategoryItem from "./CategoryItem/CategoryItem.js"
import SortItem from "./SortItem/SortItem.js"
import { connect } from "react-redux"
import OpenApp from "src/common/CallApp"

const Item = List.Item

@connect((state) => ({
  user: state.user,
}))
class _Scholarship extends Component {
  constructor(props) {
    super(props)
    this.state = {
      un_affirm: 0,
      account: 0.0,
      drawCashHtml: false,
      drawCashWechat: false,
      waitMoneyDetail: false,
      codeSrc: "",
      dataSource: new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2,
      }),
      page: 1,
      useBodyScroll: true,
      isLoading: false,
      showNoData: false,
      hasMore: true,
      tab: 0,
    }
    this.downImage = React.createRef()
    this.tabIndex = 0
    this.searchObject = {}
    this.rData = []
  }

  tabList = [
    { title: "课程列表", sub: "1" },
    { title: "分销榜单", sub: "2" },
  ]
  // 返回上一页
  goback = () => {
    this.props.history.push("/my")
  }
  // 关闭弹框
  closeModal = () => {
    this.setState({
      drawCashWechat: false,
      drawCashHtml: false,
      waitMoneyDetail: false,
    })
  }
  // 显示代收款提示modal
  showDetail = () => {
    this.setState({
      waitMoneyDetail: true,
    })
  }
  // 提现按钮 根据是否在微信环境中显示提示
  drawCash = () => {
    if (browser.isWeixin) {
      this.setState({
        drawCashWechat: true,
      })
    } else {
      this.setState({
        drawCashHtml: true,
      })
    }
    const { hasError, data = {} } = this.props.user
    if (hasError) {
      Toast.info("请登录提现！", undefined, undefined, false)
      return
    }

    http
      .get(`${API["base-api"]}/wx/user_temporary_qrcode/${data.uid}`)
      .then((res) => {
        if (res.data.errno === 0) {
          if (browser.isWeixin) {
            this.setState({
              codeSrc: res.data.data.qr_image,
            })
          } else {
            this.setState({
              codeSrc: res.data.data.qr_image,
            })
          }
        } else {
          Toast.info(res.data.data.msg, 2)
        }
      })
  }
  // 去登陆
  toLogin = () => {
    this.props.history.push("/passport")
  }
  // 跳转到规则说明页
  showDocument = () => {
    this.props.history.push("/document")
  }
  // 保存二维码 TODO 失败
  saveImage = () => {}
  // 分销赚钱
  shareCategory = (course_id, money) => {
    const { hasError, data = {} } = this.props.user
    if (hasError) {
      Toast.info("请登录后分享！", undefined, undefined, false)
      return
    }
    http.post(`${API.home}/dist/createCode`, { course_id }).then((res) => {
      if (res.data.code === 200) {
        this.props.history.push(
          `/shareposter?courseId=${course_id}&dist_first=${money}&uid=${data.uid}&dist_code=${res.data.data.code}`
        )
      }
    })
  }
  onEndReached = () => {
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }

    this.setState({
      isLoading: true,
    })

    let apiUrl = `${API.home}/m/dist/course_list`

    if (this.tabIndex === 1) {
      apiUrl = `${API.home}/m/dist/commission_ranking_list`
    }

    http
      .get(apiUrl)
      .then((res) => {
        if (res.data.code !== 200) {
          this.setState({ isLoading: false })
          return
        }

        let newData = []
        if (this.tabIndex === 0) {
          if (!res.data.data.list || res.data.data.list.length === 0) {
            this.setState({
              isLoading: false,
              hasMore: false,
              showNoData: this.searchObject.page === 1,
            })
            return
          }
          newData = res.data.data.list
        } else {
          if (!res.data.data || res.data.data.length === 0) {
            this.setState({
              isLoading: false,
              hasMore: false,
              showNoData: this.searchObject.page === 1,
            })
            return
          }
          newData = res.data.data
        }

        this.searchObject.page++

        this.rData = [...this.rData, ...newData]

        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(this.rData),
          isLoading: false,
          // hasMore: newData.length >= 10,
          hasMore: false,
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  componentDidMount() {
    if (this.state.useBodyScroll) {
      document.body.style.overflow = "auto"
    } else {
      document.body.style.overflow = "hidden"
    }
    http.get(`${API.home}/m/user_account`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          ...res.data.data,
        })
      }
    })
    this.onEndReached()
  }

  toCourseDetail = (id) => {
    const { history } = this.props
    history.push(`/detail?id=${id}`)
  }

  render() {
    const row = (rowData, rowID) => {
      return (
        <CategoryItem
          {...rowData}
          key={rowID}
          share={this.shareCategory}
          toDetail={this.toCourseDetail}
        />
      )
    }

    const row1 = (rowData, rowID) => {
      return (
        <SortItem
          {...rowData}
          sortNum={this.rData.indexOf(rowData) + 1}
          key={rowID}
        />
      )
    }

    /* eslint-disable-next-line no-unused-vars*/
    const separator = (sectionID, rowID) => (
      <div key={`${sectionID}-${rowID}`}>
        <WhiteSpace />
      </div>
    )
    const {
      un_affirm,
      account,
      drawCashHtml,
      drawCashWechat,
      waitMoneyDetail,
      codeSrc,
    } = this.state
    const { hasError } = this.props.user
    return (
      <div className={"scholarship"}>
        <div className={"account-container"}>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <Flex justify="between">
              <i
                className={"iconfont iconiconfront-68 back"}
                onClick={this.goback}
              ></i>
              <span className={"common-ft-15"}>账户资金</span>
              <i
                className={"iconfont iconiconfront-22 tip-info"}
                onClick={this.showDocument}
              ></i>
            </Flex>
          </WingBlank>
          <WhiteSpace></WhiteSpace>
          <WingBlank>
            <span className={"common-ft-14"}>可提现余额：</span>
          </WingBlank>
          <WhiteSpace></WhiteSpace>
          {hasError ? (
            <>
              <WhiteSpace></WhiteSpace>
              <Flex justify="center">
                <div className={"login-check"} onClick={this.toLogin}>
                  登录后查看
                </div>
              </Flex>
            </>
          ) : (
            <>
              <Flex justify="center" align="baseline" className={"drawCash"}>
                <span className={"common-ft-25"}>{account}</span>
                <span className={"common-ft-15"}>元</span>
              </Flex>
              <WhiteSpace></WhiteSpace>
              <Flex justify="center">
                <div className={"draw-cash"} onClick={this.drawCash}>
                  提现
                </div>
              </Flex>
            </>
          )}
        </div>

        <div className={"over-am-list-item"}>
          {hasError ? null : (
            <>
              <div>
                <List>
                  <Item
                    arrow="horizontal"
                    style={{ padding: "0 0 0 15px" }}
                    onClick={this.showDetail}
                  >
                    <Flex direction="row" justify="between">
                      <span className={"common-ft-15"}>待确认金额</span>
                      <span className={"money"}>{un_affirm}</span>
                    </Flex>
                  </Item>
                </List>
              </div>
              <WhiteSpace></WhiteSpace>
            </>
          )}
          <div style={{ backgroundColor: "#FFF" }}>
            <Tabs
              tabs={this.tabList}
              initialPage={0}
              swipeable={false}
              // onChange={this.changeTab}
              onChange={(tab, index) => {
                this.tabIndex = index
                this.pageIndex = 1
                this.rData = []
                this.searchObject.page = 1
                this.setState(
                  {
                    isLoading: false,
                    hasMore: true,
                    dataSource: this.state.dataSource.cloneWithRows(this.rData),
                    tab: index,
                  },
                  () => {
                    this.onEndReached()
                  }
                )
              }}
            ></Tabs>
            {this.state.tab === 0 ? (
              <div style={{ marginTop: "15px", backgroundColor: "#FFF" }}>
                {this.state.dataSource._cachedRowCount !== 0 ? (
                  <ListView
                    useBodyScroll={this.state.useBodyScroll}
                    dataSource={this.state.dataSource}
                    renderRow={row}
                    renderBodyComponent={() => <div />}
                    onEndReached={this.onEndReached}
                    pageSize={4}
                    onEndReachedThreshold={100}
                  />
                ) : null}
              </div>
            ) : null}

            {this.state.tab === 1 ? (
              <div>
                <Flex justify="center" align="center" className={"runtimeList"}>
                  榜单实时更新，仅显示前50名
                </Flex>
                {this.state.dataSource._cachedRowCount !== 0 ? (
                  <ListView
                    useBodyScroll={this.state.useBodyScroll}
                    dataSource={this.state.dataSource}
                    renderRow={row1}
                    renderBodyComponent={() => <div />}
                    onEndReached={this.onEndReached}
                    pageSize={4}
                    onEndReachedThreshold={100}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        <Modal
          visible={drawCashWechat}
          transparent
          maskClosable={true}
          onClose={this.closeModal}
          title="提现"
          footer={[
            {
              text: "确认",
              onPress: () => {
                this.closeModal()
              },
            },
          ]}
          className={"wechatModal"}
          style={{ width: "300px" }}
        >
          <p style={{ fontSize: "13px", color: "#666666" }}>
            提现金额将通过微信零钱转账给您，识别下方二维码，关注【七月在线】服务号申请提现。
          </p>
          <WhiteSpace size="sm"></WhiteSpace>
          <Flex justify="center">
            <img
              src={codeSrc}
              style={{ width: "90px", height: "90px", backgroundColor: "#666" }}
              alt="二维码"
            />
          </Flex>
        </Modal>

        <Modal
          visible={drawCashHtml}
          transparent
          maskClosable={true}
          onClose={this.closeModal}
          style={{ width: "300px" }}
        >
          <p
            style={{ color: "#333333", fontSize: "16px", textAlign: "center" }}
          >
            提现
          </p>
          <WhiteSpace size="lg"></WhiteSpace>
          <p style={{ fontSize: "12px", color: "#666666", textAlign: "left" }}>
            提现金额将通过微信零钱转账给您，请微信扫码关注【七月在线】服务号后申请提现。
          </p>
          <WhiteSpace size="lg"></WhiteSpace>
          <Flex justify="center">
            <img
              src={codeSrc}
              style={{ width: "90px", height: "90px" }}
              alt="二维码"
            />
          </Flex>
          <WhiteSpace size="sm"></WhiteSpace>
          <Flex justify="center" style={{ color: "#333333", fontSize: "12px" }}>
            长按二维码保存到相册
          </Flex>
          <i
            onClick={this.closeModal}
            className="iconfont iconiconfront-2"
            style={{
              zIndex: 99,
              fontSize: "40px",
              color: "#fff",
              position: "fixed",
              top: "70%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          ></i>
        </Modal>

        <Modal
          visible={waitMoneyDetail}
          transparent
          maskClosable={true}
          onClose={this.closeModal}
          title="待确认金额"
          footer={[
            {
              text: "知道了",
              onPress: () => {
                this.closeModal()
              },
            },
          ]}
          style={{ width: "300px" }}
        >
          <p
            style={{
              fontSize: "13px",
              color: "#666666",
              textAlign: "left",
              lineHeight: "20px",
            }}
          >
            1.尚未开课的直播课程，用户购买后归属您的佣金会暂时存放在『待确认金额』中。
          </p>
          <p
            style={{
              fontSize: "13px",
              color: "#666666",
              textAlign: "left",
              lineHeight: "20px",
            }}
          >
            2.该课程正式开课时，如果用户没有退款，对应的订单金额会自动转入您的账号余额，否则该佣金会自动收回。
          </p>
          <WhiteSpace></WhiteSpace>
          <Flex style={{ fontSize: "14px", color: "#0099FF" }} justify="center">
            <span style={{ color: "#333", marginRight: "10px" }}>
              待确认订单详情可
            </span>
            <OpenApp className="toApp" text="前往APP查看" />
          </Flex>
        </Modal>
      </div>
    )
  }
}

// const mapStateToProps = (state) => {
//     return {
//         user: {
//             ...state.user
//         },
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {};
// };

// export const Scholarship = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(_Scholarship);

export default _Scholarship
