import React, { PureComponent } from "react"
import "./accountLogin.scss"
import { Link } from "react-router-dom"
import { withFormik, FastField, Form } from "formik"
import { compose } from "redux"
import { accountLogin } from "src/store/userAction"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { HeaderBar } from "src/common"

import Header from "../common/Header"
import Input from "../common/Input"
import LoginButton from "../common/LoginButton"
import PasswordInput from "../common/passwordInput"
import { Toast } from "antd-mobile"

class AccountLogin extends PureComponent {
  render() {
    const { errors, values } = this.props
    return (
      <div className={"account-login"}>
        <HeaderBar arrow={true} title={"登录"} />
        <Header />
        <Form className="login-info">
          <FastField name="account">
            {({ field }) => (
              <Input
                {...field}
                type={"text"}
                placeholder={"手机/邮箱/昵称"}
                wrapperClass={"tel-input"}
                icon={
                  <i
                    className={"iconfont iconshouji"}
                    style={{ fontSize: "22px", left: "10px" }}
                  />
                }
              />
            )}
          </FastField>
          <FastField name="password">
            {({ field }) => (
              <PasswordInput
                {...field}
                autoComplete={"on"}
                placeholder={"密码"}
                icon={<i className={"iconfont iconiconfront-74 lock-icon"} />}
              />
            )}
          </FastField>
          <LoginButton
            active={values.account && values.password && isEmpty(errors)}
          />
          <Link
            className={"forgot-password-btn"}
            to="/passport/forgot-password"
          >
            忘记密码
          </Link>
        </Form>
      </div>
    )
  }
}

const formikConfig = {
  mapPropsToValues: () => ({
    account: "",
    password: "",
  }),
  handleSubmit(values, formikBag) {
    const { account: username, password } = values
    const {
      props,
      props: { history },
    } = formikBag
    const from = props.location.state && props.location.state.from
    props
      .accountLogin({
        username,
        password,
        redirect:
          from &&
          window.location.origin + from.pathname + from.search + from.hash,
      })
      .then((res) => {
        if (res.hasError) {
          if (res.code === 4004) {
            history.push(`/passport/binding-tel?username=${username}`)
            return
          }
          Toast.info(res.msg, 2, null, false)
        }
      })
  },
  validate: (values) => {
    const errors = {}
    if (!values.account) {
      errors.account = "账号不能为空"
    } else if (!values.password) {
      errors.password = "密码不能为空"
    }
    return errors
  },
}

export default compose(
  connect((state) => ({ user: state.user }), { accountLogin }),
  withFormik(formikConfig)
)(AccountLogin)
