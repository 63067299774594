export const getParam = (key, str) => {
  const _s = str ? str : window.location.href
  const re = new RegExp(`[?#&](${key})=([^=&#?]+)`, "ig")
  let found = re.exec(_s)
  return found ? found[2] : null
}

const html = (content) => ({
  __html: htmlDecode(content),
})

const htmlDecode = (content) => {
  let e = document.createElement("div")
  e.innerHTML = content
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue
}

//加载网易易盾辅助函数
function getTimestamp(msec) {
  msec = !msec && msec !== 0 ? msec : 1
  return parseInt(new Date().valueOf() / msec, 10)
}

function loadScript(src, cb) {
  let head = document.head || document.getElementsByTagName("head")[0]
  let script = document.createElement("script")

  cb = cb || function () {}

  script.type = "text/javascript"
  script.src = src

  if (!("onload" in script)) {
    script.onreadystatechange = function () {
      if (this.readyState !== "complete" && this.readyState !== "loaded") return
      this.onreadystatechange = null
      cb(script)
    }
  }

  script.onload = function () {
    this.onload = null
    cb(script)
  }

  head.appendChild(script)
}

function initCaptcha(cb) {
  if (window.initNECaptcha) {
    cb()
  } else {
    const url = "//cstaticdun.126.net/load.min.js?t=" + getTimestamp(60 * 1000)
    loadScript(url, cb)
  }
}

function initCaptchaNC(cb) {
  if (window.NoCaptcha && typeof window.NoCaptcha.init === "function") {
    cb()
  } else {
    const url = "//g.alicdn.com/sd/nch5/index.js?t=" + getTimestamp(60 * 1000)
    loadScript(url, cb)
  }
}

function validateTel(tel) {
  return /^1[3-9](\d{9})$/.test(tel)
}

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const browser = (function () {
  const ua = navigator.userAgent
  return {
    isWeixin: /MicroMessenger/i.test(ua),
    isAndroid: /Android/i.test(ua),
    isIOS: /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua),
    isIPad: /iPad/i.test(ua),
    isAndroidApp: /Android/i.test(ua) && getParam("version"),
    isIOSApp: /iPhone/i.test(ua) && getParam("version"),
  }
})()

const isValidUrl = (str) => {
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(
    str
  )
}

export { default as http } from "./http.ts"
export { default as wxShare } from "./wechat/share"
export {
  html,
  initCaptcha,
  initCaptchaNC,
  validateTel,
  validateEmail,
  browser,
  isValidUrl,
  loadScript,
  getTimestamp,
}
export { default as SendMessageToApp } from "./app"
