import { http } from "src/utils"
import { encrypt } from "src/components/passport/encryption"
import jsCookie from "js-cookie"

const accountLogin = (user) => (dispatch) => {
  return http
    .post(`${API["passport-api"]}/m/login/accountLogin`, {
      user_name: user.username,
      password: encrypt(user.password),
      is_encrypt: 1,
      redirect: encodeURIComponent(user.redirect),
    })
    .then((res) => {
      return storeUser(res, dispatch)
    })
}

const studentLogin = (params) => (dispatch) => {
  return http
    .post(`${API["passport-api"]}/phone_reg`, {
      phone: params.tel,
      password: params.password,
      code: params.code,
      area_code: params.num,
      school_name: params.school,
      college_name: params.college,
      student_id: params.uid,
      student_name: params.name,
      redirect: encodeURIComponent(params.redirect),
      type: 1,
    })
    .then((res) => {
      const { errno, data } = res.data
      let result = {}
      if (errno === 0) {
        result = {
          data: {
            errno: 200,
            data,
          },
        }
      } else {
        result = res
      }
      return storeUser(result, dispatch)
    })
}

const quickLogin = (user) => (dispatch) => {
  return http
    .post(`${API["passport-api"]}/m/login/quickLogin`, {
      ...user,
      plat: 5,
    })
    .then((res) => {
      return storeUser(res, dispatch)
    })
}

const storeUser = (res, dispatch) => {
  const data = res.data
  let payload
  if (data.errno === 200) {
    const {
      user_name: username,
      avatar_file: avatar,
      is_vip: isVIP,
      ...rest
    } = data.data.user_info
    payload = {
      hasError: false,
      msg: data.msg,
      data: { username, avatar, isVIP, ...rest },
    }
  } else {
    payload = {
      hasError: true,
      msg: data.msg,
      data: {},
      code: data.errno,
    }
  }
  dispatch(setCurrentUser(payload))
  return payload
}

const SET_CURRENT_USER = "SET_CURRENT_USER"
const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload: { ...payload, isFetching: false },
})

const logout = () => (dispatch) => {
  jsCookie.remove("token", { path: "/", domain: ".julyedu.com" })
  jsCookie.remove("uid", { path: "/", domain: ".julyedu.com" })
  dispatch(setCurrentUser({ hasError: true }))
}

const UPDATE_USER = "UPDATE_USER"
const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
})

const START_FETCH_USER = "START_FETCH_USER"
const startFetchUser = () => ({
  type: START_FETCH_USER,
})

const END_FETCH_USER = "END_FETCH_USER"
const endFetchUser = () => ({
  type: END_FETCH_USER,
})

export {
  accountLogin,
  studentLogin,
  SET_CURRENT_USER,
  setCurrentUser,
  quickLogin,
  logout,
  UPDATE_USER,
  updateUser,
  START_FETCH_USER,
  startFetchUser,
  END_FETCH_USER,
  endFetchUser,
}
