import React, { Component } from "react"
import "./index.scss"
import { http } from "src/utils"

class UserGift extends Component {
  state = {
    user_gift: "",
  }

  componentDidMount() {
    http.get(`${API["base-api"]}/m/home/popup`).then((res) => {
      const { errno, data } = res.data
      if (errno === 200) {
        this.setState({
          user_gift: data.new_user_gift.prize_img,
        })
      }
    })
  }

  get_newerModal = () => {
    this.props.get_newerModal()
  }
  close = () => {
    this.props.close()
  }

  render() {
    const { user_gift } = this.state
    return (
      <div className={"user-gift-popup"}>
        <div className={"user-gift-bgimg"}>
          <img onClick={this.get_newerModal} src={user_gift} alt="" />
          <img
            className={"close_gift_box"}
            src="https://cdn.julyedu.com/tinypng-common/close_icon.png"
            onClick={this.close}
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default UserGift
