import React, { Component } from "react"
import "./index.scss"
import { initCaptchaNC } from "src/utils"

const appkey = "FFFF0N000000000090FC"
const scene = "nc_login_h5"

class CaptchaAli extends Component {
  nc = null
  el = null
  state = {
    isLoaded: false,
  }

  componentDidMount() {
    const _this = this
    this.el &&
      initCaptchaNC(() => {
        const nc_token = [appkey, new Date().getTime(), Math.random()].join(":")
        this.nc = NoCaptcha.init({
          renderTo: "#nc",
          appkey,
          scene,
          token: nc_token,
          elementID: ["tel"],
          bannerHidden: false,
          callback(data) {
            _this.props.onVerify({
              app_key: appkey,
              scene,
              token: nc_token,
              session_id: data.csessionid,
              sig: data.sig,
            })
          },
          error(s) {
            console.log(s)
          },
        })
        NoCaptcha.setEnabled(true)
        this.nc.reset()
        this.props.getInstance(this.nc)
      })
  }

  render() {
    const { mb = 30 } = this.props
    return (
      <div id={"captcha"} style={{ marginBottom: `${mb}px` }}>
        <div id="nc" ref={(el) => (this.el = el)}></div>
      </div>
    )
  }
}

export default CaptchaAli
