import React, { Component } from "react"
import Swiper from "swiper"
import "./index.scss"
import "swiper/dist/css/swiper.min.css"
import { Link } from "react-router-dom"

class Index extends Component {
  container = null
  swiper = null

  componentDidMount() {
    if (this.props.bannerList.length > 1) {
      this.swiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true,
        spaceBetween: 12,
        centeredSlides: true,
        slidesPerView: "auto",
        loopedSlides: 3,
        autoplay: true,
      })
    }
  }

  componentWillUnmount() {
    this.swiper && this.swiper.destroy()
  }

  render() {
    const { bannerList } = this.props
    return (
      <div className={"index-banner-swiper"}>
        <div className={"swiper-container"} ref={(el) => (this.container = el)}>
          <ul className="swiper-wrapper">
            {bannerList &&
              bannerList.length > 0 &&
              bannerList.map((item, index) => {
                return (
                  <li className={"swiper-slide"} key={index}>
                    {Number.isNaN(parseInt(item.jump_url)) ? (
                      <a href={item.jump_url} key={index}>
                        <img className="item" src={item.name} alt="" />
                      </a>
                    ) : (
                      <Link
                        to={{
                          pathname: "/detail",
                          search: `?id=${item.jump_url}`,
                        }}
                        key={index}
                      >
                        <img className="item" src={item.name} alt="" />
                      </Link>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Index
