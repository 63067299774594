import { combineReducers } from "redux"
import myCourses from "src/components/study/myCourses/reducers"
import courseInfo from "src/components/detail/reducers"
import user from "./userReducer"
import country from "src/components/country/countryRedux"
import intelligentRecommend from "src/components/intelligent-recommend/store"
import anniversary2020Question from "src/components/activity/2020-717/question/store/reducer" //2020周年庆活动答题页
import noTraceValidation from "./no-trace-validation/reducer"

const reducer = combineReducers({
  myCourses,
  courseInfo,
  user,
  country,
  intelligentRecommend,
  anniversary2020Question,
  noTraceValidation,
})

export default reducer
