/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react"
import { Flex } from "antd-mobile"
import "./SortItem.scss"

// const Item = FLex.Item;
const FIRST = "https://cdn.julyedu.com/order/PC/jin1_icon.png"
const SECOND = "https://cdn.julyedu.com/order/PC/yin2_icon.png"
const THIRD = "https://cdn.julyedu.com/order/PC/tong_icon.png"

export default class SortItem extends Component {
  render() {
    const { sortNum, avatar_file, user_name, total_account } = this.props
    let sortImgSrc = ""
    if (sortNum === 1) {
      sortImgSrc = FIRST
    }
    if (sortNum === 2) {
      sortImgSrc = SECOND
    }
    if (sortNum === 3) {
      sortImgSrc = THIRD
    }
    return (
      <div>
        <Flex
          align="center"
          className={"sortItem"}
          style={{ backgroundColor: "#FFF" }}
        >
          <div className={"num"}>
            <Flex justify="center">
              {Number(sortNum) > 3 ? (
                sortNum
              ) : (
                <img
                  src={sortImgSrc}
                  style={{ width: "18px", height: "22px" }}
                ></img>
              )}
            </Flex>
          </div>
          <div className={"avatar"}>
            <Flex justify="start">
              <img src={avatar_file} className={"avatarImg"}></img>
              <span
                className={"text-overflow-one"}
                style={{ color: "#333333" }}
              >
                {user_name}
              </span>
            </Flex>
          </div>
          <div className={"money"}>
            <Flex justify="center">{`${total_account}元`}</Flex>
          </div>
        </Flex>
      </div>
    )
  }
}
