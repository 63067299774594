import React, { Component } from "react"
import "./index.scss"
import { withRouter } from "react-router-dom"
import { browser, getParam } from "src/utils"

class HeaderBar extends Component {
  goBack = () => {
    const { state, hash } = this.props.location
    if (hash.includes("goback")) {
      return window.history.go(-1)
    }
    if (browser.isWeixin && getParam("code") && getParam("state")) {
      window.history.go(-2)
    }
    if (state.records && state.records.length > 1) {
      window.history.go(-1)
    } else if (state.from && state.from.pathname) {
      window.location.replace(`${state.from.pathname}${state.from.search}`)
    } else {
      window.location.href = window.location.origin
    }
  }

  toLink = () => {
    const { toHref } = this.props
    // console.log(toHref);
    window.location.replace(toHref)
  }

  goShop = () => {
    this.props.history.push("/shopcart")
  }

  render() {
    const { toHref, home } = this.props
    return (
      <div className="detail-header" style={{ ...this.props.style }}>
        {!toHref && this.props.arrow && (
          <i
            className="iconfont iconiconfront-68"
            onClick={this.props.goBack || this.goBack}
          ></i>
        )}
        {toHref && typeof toHref === "function" && (
          <i className="iconfont iconiconfront-68" onClick={toHref}></i>
        )}
        {toHref && typeof toHref === "string" && (
          <i className="iconfont iconiconfront-68" onClick={this.toLink}></i>
        )}
        <span className="herder">{this.props.title}</span>
        {this.props.cart && (
          <i
            className="iconfont icongouwuche-xianxing"
            onClick={this.goShop}
          ></i>
        )}
        {this.props.delete && (
          <i
            className="iconfont iconiconfront-56"
            onClick={this.props.toDelete}
          ></i>
        )}
        {home && <i className="iconfont iconshouye-xianxing"></i>}
      </div>
    )
  }
}

export default withRouter(HeaderBar)
