import React, { Component } from "react"
import "./binding-tel.scss"
import { withFormik, Field, Form } from "formik"
import { http, getParam, isValidUrl } from "src/utils"
import { compose } from "redux"
import { connect } from "react-redux"
import { setCurrentUser } from "src/store/userAction"
import { HeaderBar, CaptchaAli } from "src/common"
import ClearableInput from "../common/clearableInputWithCountryCodes"
import Button from "../common/Button"
import VeriCodeInput from "../common/veriCodeInput"
import { Toast } from "antd-mobile"
import { isEmpty } from "lodash"

class BindingTel extends Component {
  state = {
    validate: null,
    captchaInstance: null,
    validationData: null,
  }

  getCaptchaInstance = (instance) => {
    this.setState({
      captchaInstance: instance,
    })
  }
  onVerify = (data) => {
    this.setState({
      validate: true,
      validationData: data,
    })
  }

  render() {
    const { values, errors, country } = this.props
    return (
      <>
        <HeaderBar title={"绑定手机号"} arrow={true} />
        <div className={"binding-tel"}>
          <p className={"title"}>为提高您的账号安全，请绑定手机号</p>
          <Form>
            <Field name="tel">
              {({ field, form }) => {
                return (
                  <ClearableInput
                    {...field}
                    setFieldValue={form.setFieldValue}
                    placeholder={"请输入需要绑定的手机号"}
                    wrapperClass={"tel"}
                    country={country}
                  />
                )
              }}
            </Field>
            <Field name="veriCode">
              {({ field }) => {
                return (
                  <VeriCodeInput
                    {...field}
                    className={"verification"}
                    icon={
                      <i
                        className={"iconfont iconduanxin"}
                        style={{ fontSize: "20px", left: "12px" }}
                      />
                    }
                    placeholder={"验证码"}
                    account={values.tel}
                    tel={values.tel}
                    instance={this.state.captchaInstance}
                    action={"auth"}
                    country={country}
                    validationData={this.state.validationData}
                  />
                )
              }}
            </Field>
            <CaptchaAli
              onVerify={this.onVerify}
              getInstance={this.getCaptchaInstance}
              mb={0}
            />
            <Button
              className={"complete-btn"}
              active={values.tel && values.veriCode && isEmpty(errors)}
            >
              完成
            </Button>
          </Form>
        </div>
      </>
    )
  }
}

const formikConfig = {
  mapPropsToValues() {
    return {
      tel: "",
      veriCode: "",
    }
  },
  validateOnChange: true,
  validate(values) {
    let errors = {}
    if (!/\d/.test(values.tel)) {
      errors.tel = "请输入正确的手机号"
    }
    if (!values.veriCode) {
      errors.veriCode = "请填写验证码"
    }
    return errors
  },
  handleSubmit(values, { props }) {
    const username = getParam("username")
    if (username) {
      //老账号绑定手机号
      http
        .post(`${API["passport-api"]}/m/personal/bindMobile`, {
          phone_num: values.tel,
          code: values.veriCode,
          type: 1,
          area_code: "00" + props.country.num,
          user_name: username,
        })
        .then((res) => {
          const { data, errno, msg } = res.data
          if (errno === 200) {
            props.setCurrentUser({
              hasError: false,
              data: {
                username: data.user_name,
                avatar: data.avatar_file,
                isVip: parseInt(data.vip),
                token: data.access_token,
                uid: data.uid,
              },
            })
            const { from } = props.location.state || {
              from: { pathname: "/" },
            }
            props.history.push(from)
          } else {
            Toast.info(msg, 2, null, false)
          }
        })
    } else {
      const uid = getParam("uid")
      const params = {
        phone_num: values.tel,
        phone_code: values.veriCode,
        mkey: getParam("mkey"),
        area_code: "00" + props.country.num,
        plat: 5,
        type: uid ? 1 : 2,
      }
      if (uid) {
        params.uid = uid
      }
      http.post(`${API["passport-api"]}/bind_mobile`, params).then((res) => {
        const data = res.data
        if (data.errno === 200) {
          const { history } = props
          if (data.data["is_set_pwd"]) {
            history.replace(`/passport/set-password`, {
              user: {
                hasError: false,
                data: {
                  uid: data.data.uid,
                },
                msg: data.data.msg,
                stage: "binding",
              },
            })
          } else {
            const redirect = localStorage.getItem("binding_redirect")
            if (redirect) {
              localStorage.removeItem("binding_redirect")
              isValidUrl(redirect)
                ? window.location.assign(JSON.parse(redirect))
                : history.replace(JSON.parse(redirect))
            } else {
              window.location.assign(data.data["jump_url"])
            }
          }
        } else {
          Toast.info(data.msg, 2, null, false)
        }
      })
    }
  },
}
export default compose(
  connect((state) => ({ country: state.country }), { setCurrentUser }),
  withFormik(formikConfig)
)(BindingTel)
