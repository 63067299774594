import React from "react"
import { NavLink, withRouter } from "react-router-dom"
import "./index.scss"

const navLinkConfig = [
  {
    to: "/",
    exact: true,
    icon: "index-icon",
    activeIcon: "index-icon-active",
    text: "首页",
  },
  {
    to: "/classify",
    exact: false,
    icon: "classify-icon",
    activeIcon: "classify-icon-active",
    text: "分类",
  },
  {
    to: "/study",
    exact: false,
    icon: "study-icon",
    activeIcon: "study-icon-active",
    text: "学习",
  },
  {
    to: "/my",
    exact: false,
    icon: "my-icon",
    activeIcon: "my-icon-active",
    text: "我的",
  },
]

const NavBar = React.memo(({ location }) => {
  return (
    <div className="nav-bar">
      {navLinkConfig.map((item) => {
        let { icon, text, activeIcon, ...rest } = item
        return (
          <NavLink
            activeClassName={"active"}
            className={"nav-item"}
            key={icon}
            {...rest}
          >
            <i
              className={`icon ${
                item.to.length > 1
                  ? location.pathname.startsWith(item.to)
                    ? activeIcon
                    : icon
                  : location.pathname === item.to
                  ? activeIcon
                  : icon
              }`}
            />
            <span>{text}</span>
          </NavLink>
        )
      })}
      <div className={"click-able"}></div>
    </div>
  )
})

export default withRouter(NavBar)
