/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react"
import { Flex, WingBlank } from "antd-mobile"
import "./CategoryItem.scss"

export default class CategoryItem extends Component {
  render() {
    const {
      course_title,
      price1,
      price0,
      first_level_tip,
      image_name,
      upgrade,
      upgradeImg,
      course_id,
      share,
      toDetail,
    } = this.props
    return (
      <WingBlank>
        <div className={"scholarship-category"}>
          {/* <Link to={`/detail?id=${course_id}`} > */}
          <div className={"imageContainer"} onClick={() => toDetail(course_id)}>
            <img className={"image"} src={image_name}></img>
            {upgrade ? <img src={upgradeImg}></img> : null}
          </div>
          {/* </Link> */}
          <div className={"content"}>
            <Flex
              direction="column"
              justify="around"
              align="start"
              style={{ height: "90px" }}
            >
              {/* <Link to={`/detail?id=${course_id}`} > */}
              <p
                className={"text-overflow-one"}
                onClick={() => toDetail(course_id)}
              >
                {course_title}
              </p>
              {/* </Link> */}
              <p>
                <span className={"priceNow"}>{`￥${price1}`}</span>
                <span className={"pricePrimary"}>{`￥${price0}`}</span>
              </p>
              <div
                className={"shareMoney"}
                onClick={() => {
                  share(course_id, first_level_tip)
                }}
              >{`分享赚${first_level_tip}元`}</div>
            </Flex>
          </div>
        </div>
      </WingBlank>
    )
  }
}
