const UPDATE_CAPTCHA_STATE = "UPDATE_CAPTCHA_STATE"
export const updateCaptchaState = (payload) => {
  return {
    type: UPDATE_CAPTCHA_STATE,
    payload,
  }
}

const SHOW_CAPTCHA_MODAL = "SHOW_CAPTCHA_MODAL"
export const showCaptchaModal = () => {
  return {
    type: SHOW_CAPTCHA_MODAL,
  }
}

const CLOSE_CAPTCHA_MODAL = "CLOSE_CAPTCHA_MODAL"
export const closeCaptchaModal = () => {
  return {
    type: CLOSE_CAPTCHA_MODAL,
  }
}

const VALIDATION_PASSED = "VALIDATION_PASSED"
export const validationPassed = () => {
  return {
    type: VALIDATION_PASSED,
  }
}

const START_FETCH = "START_FETCH"
export const startFetchNoTrace = () => ({
  type: START_FETCH,
})

const END_FETCH = "END_FETCH"
export const endFetchNoTrace = () => ({
  type: END_FETCH,
})

const initialState = {
  isShowCaptcha: false,
  isNeedValidation: false,
  isFetching: false,
}

export default function noTraceValidation(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CAPTCHA_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SHOW_CAPTCHA_MODAL:
      return {
        ...state,
        ...{ isShowCaptcha: true },
      }
    case CLOSE_CAPTCHA_MODAL:
      return {
        ...state,
        ...{ isShowCaptcha: false },
      }
    case VALIDATION_PASSED:
      return {
        isNeedValidation: false,
        isShowCaptcha: false,
        isFetching: false,
      }
    case START_FETCH:
      return {
        ...state,
        isFetching: true,
      }
    case END_FETCH:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
