import React, { Component } from "react"
import ClearableInput from "src/common/ClearableInput"
import "./index.scss"
import { Link } from "react-router-dom"

class ClearableInputWithCountryCodes extends Component {
  render() {
    const { country, ...rest } = this.props
    return (
      <div className={"clearable-input-with-country-codes"}>
        <div className="country-code">
          <Link to={"/country"}>
            +{(country && country.num) || 86}
            <i className={"iconfont iconiconfront-69"} />
          </Link>
        </div>
        <ClearableInput {...rest} />
      </div>
    )
  }
}

export default ClearableInputWithCountryCodes
