/* global wx */
import { http, browser } from "src/utils"

// const url = `https://res.wx.qq.com/open/js/jweixin-1.4.0.js`
const url = `https://res.wx.qq.com/open/js/jweixin-1.6.0.js`
const appId = "wx23dac6775ac82877"
const jsApiList = [
  "updateAppMessageShareData",
  "updateTimelineShareData",
  "onMenuShareAppMessage",
  "onMenuShareTimeline",
]

export const getSignature = async (config = {}) => {
  let res = await http.post(`${API["base-api"]}/m/sale/signature`, {
    url:
      browser.isWeixin && browser.isIOS
        ? sessionStorage.getItem("enter_url")
        : window.location.href.split("#")[0],
  })
  return wx.config({
    debug: false, // 开启调试模式,
    appId, // 必填，公众号的唯一标识
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.nonce_str, // 必填，生成签名的随机串
    signature: res.data.signature, // 必填，签名，见附录1
    jsApiList,
    ...config,
  })
}

export const getWXObject = () => {
  return new Promise((resolve) => {
    if (!window.wx) {
      return loadScript(url).then(() => resolve())
    }
    resolve()
  })
}

function loadScript(url) {
  return new Promise((resolve) => {
    var head = document.head || document.getElementsByTagName("head")[0]
    var script = document.createElement("script")

    script.type = "text/javascript"
    script.src = url

    if (!("onload" in script)) {
      script.onreadystatechange = function () {
        if (this.readyState !== "complete" && this.readyState !== "loaded")
          return
        this.onreadystatechange = null
        resolve()
      }
    }

    script.onload = function () {
      this.onload = null
      resolve()
    }

    head.appendChild(script)
  })
}
