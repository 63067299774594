import React, { useState, useEffect } from "react"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import RouteMiddlePage from "./route-middle-page"
import Loading from "src/common/Loading"

const PrivateRoute = ({ component: Component, path, user, ...rest }) => {
  const [isLoading, setLoadingState] = useState(true)
  const [authenticated, setAuthorization] = useState(false)

  useEffect(() => {
    let _auth = !user.hasError && user.code !== 4040
    // eslint-disable-next-line no-unused-expressions
    typeof _auth !== "undefined" &&
      (setAuthorization(_auth), setLoadingState(false))
  }, [user.hasError, user.code])

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Loading isLoading={isLoading}>
            {authenticated ? (
              <Component {...props} />
            ) : (
              <RouteMiddlePage state={{ from: props.location }} />
            )}
          </Loading>
        )
      }}
    />
  )
}

export default connect((state) => ({ user: state.user }), null)(PrivateRoute)
