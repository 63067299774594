/* global initNECaptcha */
import React, { Component } from "react"
import { initCaptcha } from "src/utils"
import { BarLoader } from "react-spinners"
import "./index.scss"

const CAPTCHAID = "6b0f5f6c8f334f3693ee754ba5692e36"

class Captcha extends Component {
  state = {
    isReady: false,
  }

  componentDidMount() {
    const { getInstance, handleError, onVerify } = this.props
    const _this = this
    const el = document.getElementById("captcha")
    el &&
      initCaptcha(function () {
        initNECaptcha(
          {
            element: el,
            captchaId: CAPTCHAID,
            mode: "float",
            width: "auto",
            onReady: function (instance) {
              // 验证码一切准备就绪，此时可正常使用验证码的相关功能
              _this.setState({
                isReady: true,
              })
            },
            onVerify: function (err, data) {
              onVerify(err, data)
            },
          },
          (instance) => {
            getInstance && getInstance(instance)
          },
          (err) => {
            handleError && handleError(err)
          }
        )
      })
  }

  render() {
    return (
      <div
        className="captcha-container"
        style={{
          marginBottom: this.props.mrBtm,
        }}
      >
        {!this.state.isReady && (
          <div className="captcha-animation">
            <BarLoader />
          </div>
        )}
        <div
          id={"captcha"}
          style={{
            marginBottom: this.props.mrBtm,
          }}
        />
      </div>
    )
  }
}

export default Captcha
