const initialState = {
  question: {},
  recommends: [],
  result: {},
}

export const ADD_RESULT = "ADD_RESULT"

export const ADD_QUESTION = "ADD_QUESTION"

export const ADD_RECOMMENDS = "ADD_RECOMMENDS"

export const REDO = "REDO"

export const addData = ({ payload, type }) => {
  return {
    type,
    payload,
  }
}

export default function anniversary2020Question(state = initialState, action) {
  switch (action.type) {
    case ADD_RESULT:
      return {
        ...state,
        ...{ result: action.payload },
      }
    case ADD_QUESTION:
      return {
        ...state,
        ...{ question: action.payload },
      }
    case ADD_RECOMMENDS:
      return {
        ...state,
        ...{ recommends: action.payload },
      }
    case REDO:
      return initialState
    default:
      return state
  }
}
