const ADD_COUNTRY_NUM = "ADD_COUNTRY_NUM"
const DEL_COUNTRY_NUM = "DEL_COUNTRY_NUM"

export const addCountryNum = (payload) => ({
  type: "ADD_COUNTRY_NUM",
  payload,
})

export const delCountryNum = () => ({
  type: "DEL_COUNTRY_NUM",
})

export default (state = { num: 86, code: null }, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_COUNTRY_NUM:
      return {
        ...state,
        ...payload,
      }
    case DEL_COUNTRY_NUM:
      return {
        ...state,
        num: "",
        code: "",
        from: "",
      }
    default:
      return state
  }
}
