import {
  RECEIVE_MY_COURSES,
  SWITCH_TAB,
  NOMORE_COURSE,
  START_FETCHING_COURSES,
} from "./actions"
import { SET_CURRENT_USER } from "src/store/userAction"

const initialState = {
  switchTab: false,
  courseList: [],
  page: 0,
  statusCode: 0,
  msg: "",
  noMore: false,
  isLoading: true,
}

export default function myCourses(state = initialState, action) {
  const { type, payload = null } = action
  switch (type) {
    case RECEIVE_MY_COURSES:
      let { courseList, ...rest } = payload
      return {
        ...state,
        ...rest,
        courseList:
          Object.keys(courseList).length === 0
            ? state.courseList
            : state.courseList.concat(courseList),
        isLoading: false,
      }
    case START_FETCHING_COURSES:
      return { ...state, ...payload }
    case SWITCH_TAB:
      return { ...state, switchTab: payload }
    case NOMORE_COURSE:
      return { ...state, noMore: true }
    case SET_CURRENT_USER:
      return payload.hasError ? initialState : { ...state, page: 0 }
    default:
      return state
  }
}
