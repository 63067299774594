import { getParam, http } from "src/utils"

export const RECEIVE_COURSES_DETAIL = "RECEIVE_COURSES_DETAIL"
export const ADD_COURSES_TO_CART = "ADD_COURSES_TO_CART"

export const receiveCourseDetail = (payload) => ({
  type: RECEIVE_COURSES_DETAIL,
  payload,
})

// 加入购物车
export const addCourseToCart = (payload) => ({
  type: ADD_COURSES_TO_CART,
  payload,
})

export const fetchCoursesListIfNeeded = () => (dispatch, getState) => {
  dispatch(
    getCourses({
      courseInfo: getState().detailInfo,
    })
  )
}
export const getCourses = (id, cb) => (dispatch) => {
  const courseId = id ? id : getParam("id")
  const url = getParam("dist_code")
    ? `${API.home}/m/course/detail/${courseId}/${getParam("dist_code")}`
    : `${API.home}/m/course/detail/${courseId}`
  return http.get(url).then((res) => {
    if (res.data.code === 200) {
      dispatch(
        receiveCourseDetail({
          ...res.data.data,
        })
      )
      typeof cb === "function" && cb()
    }
  })
}
