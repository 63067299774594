import React, { Component } from "react"
import "./veri-code-input.scss"
import { http, validateEmail } from "src/utils"
import { Toast } from "antd-mobile"
import { has, isEmpty } from "lodash"

import classnames from "classnames"

import Input from "../Input"

class VeriCodeInput extends Component {
  count = 60
  state = {
    counting: false,
    count: this.count,
    isFirst: true,
  }
  timer = null

  countDown = () => {
    let { count } = this.state
    if (!this.state.isFirst) {
      Toast.info("请重新进行滑块验证", 2, null, false)
      this.props.instance.reset()
      this.setState({
        isFirst: true,
      })
      return
    }

    if (!this.state.counting) {
      if (!this.sendCode()) {
        return
      }
      this.setState({ count: count--, counting: true })
      this.timer = setInterval(() => {
        if (count <= 0) {
          clearInterval(this.timer)
          this.setState({ counting: false, count: this.count })
          return
        }
        this.setState({ count: count-- })
      }, 1000)
    }
  }

  getType = () => {
    const { email } = this.props
    if (validateEmail(email)) {
      return "email"
    }
  }

  sendCode = () => {
    if (!this.validate()) return
    this.getType() === "email" ? this.sendEmail() : this.sendSMS()
    return true
  }

  sendEmail = () => {
    const { email, validationData } = this.props
    http
      .post(`${API["passport-api"]}/send_email_code`, {
        email,
        ...validationData,
      })
      .then((res) => {
        if (res.data.errno === 0) {
          Toast.info("验证码发送成功", 2, null, false)
        } else {
          Toast.info(res.data.msg, 2, null, false)
        }
        this.setState({
          isFirst: false,
        })
      })
  }

  sendSMS = () => {
    const {
      action,
      tel,
      account,
      validationData,
      checking,
      country,
    } = this.props
    if (!tel) {
      Toast.info("请输入手机号")
      return
    }
    http
      .post(`${API["passport-api"]}/quick_sms`, {
        phone_num: tel || account,
        action: action || "login",
        area_code: "00" + country.num,
        checking,
        ...validationData,
      })
      .then((res) => {
        if (res.data.errno === 0) {
          Toast.info("验证码发送成功", 2, null, false)
        } else {
          Toast.info(res.data.msg, 2, null, false)
        }
        this.setState({
          isFirst: false,
        })
      })
  }

  validate = () => {
    const { tel, validationData, email } = this.props
    let hasTel = has(this.props, "tel")
    let content

    if (hasTel) {
      if (!tel) {
        content = "手机号码不能为空"
      }
      if (!/\d/.test(tel)) {
        content = "请输入正确格式的手机号码"
      }
    } else {
      if (!email) {
        content = "电子邮件不能为空"
      }
      if (!validateEmail(email)) {
        content = "请输入正确格式的电子邮件"
      }
    }

    if (content) {
      Toast.info(content, 2, null, false)
      return false
    }

    if (isEmpty(validationData)) {
      Toast.info("请进行滑块验证", 2, null, false)
      return false
    }
    return true
  }

  render() {
    let { className, validationData, ...rest } = this.props
    return (
      <Input type={"number"} wrapperClass={className} {...rest}>
        <button
          type="button"
          className={classnames("verify", { active: !this.state.counting })}
          onClick={this.countDown}
        >
          {this.state.counting ? `重新发送${this.state.count}s` : "发送验证码"}
        </button>
      </Input>
    )
  }
}

export default VeriCodeInput
