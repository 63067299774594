import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import LoadingComponent from "src/common/Loading"

function RouteMiddlePage(props) {
  const [isLoading, setLoadingState] = useState(true)

  useEffect(() => {
    let { user, location, history } = props

    if (!user.isFetching) {
      if (user.hasError) {
        history.push("/passport", { from: location })
        setLoadingState(false)
      } else {
        let { data } = user || { data: {} }
        if (data && Object.values(data).every((item) => !!item)) {
          setLoadingState(false)
        } else {
          history.push("/passport", { from: location })
        }
      }
    }
  }, [props, props.user.isFetching])

  return (
    <div className={"loading-route"}>
      <LoadingComponent isLoading={isLoading}>
        <div />
      </LoadingComponent>
    </div>
  )
}

export default compose(
  connect((state) => ({ user: state.user }), null),
  withRouter
)(RouteMiddlePage)
