import React, { PureComponent } from "react"
import NavBar from "src/common/NavBar"

export default function WithTab(WrappedComponent) {
  return class extends PureComponent {
    render() {
      return (
        <>
          <WrappedComponent {...this.props} />
          <NavBar />
        </>
      )
    }
  }
}
