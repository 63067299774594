import React from "react"
import "./button.scss"

import classnames from "classnames"

const Button = ({ children, active, className, ...rest }) => {
  return (
    <button
      className={classnames("custom-button", className, { active })}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
