import React, { PureComponent } from "react"
import { connect } from "react-redux"
import classnames from "classnames"
import { studentLogin } from "src/store/userAction"
import { http } from "src/utils"
import { Formik, Form, Field } from "formik"
import { Toast } from "antd-mobile"
import { HeaderBar, CaptchaAli } from "src/common"
import Header from "../common/Header"
import Input from "../common/inputWithCountryCodes"
import VeriCodeInput from "../common/veriCodeInput"

import "./index.scss"

@connect(
  (state) => ({
    country: state.country,
  }),
  {
    studentLogin,
  }
)
class StudentRoot extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      validate: null,
      captchaInstance: null,
      disabled: false,
      isSchool: false,
      isCollege: false,
      list: [],
      schoolList: [],
      schoolName: "",
      validationData: null,
    }
  }

  componentDidMount() {
    this.fetchSchoolInfo()
  }

  fetchSchoolInfo = () => {
    http.get(`${API["home"]}/sys/schools `).then((res) => {
      const { code, data } = res.data
      if (code === 200) {
        const schoolList = []
        data.forEach((item) => {
          schoolList.push(item.school)
        })
        this.setState({
          list: data,
          schoolList,
        })
      }
    })
  }

  handleToLogin = ({ tel, password, code, school, college, uid, name }) => {
    const { validate, captchaInstance } = this.state
    const { country, studentLogin, location } = this.props
    const from = location.state && location.state.from
    studentLogin({
      validate,
      tel,
      password,
      code,
      num: `00${country.num}`,
      school,
      college,
      uid,
      name,
      redirect:
        from &&
        window.location.origin + from.pathname + from.search + from.hash,
    }).then((res) => {
      if (res.hasError) {
        captchaInstance.reset()
        Toast.info(res.msg, 2, null, false)
      }
    })
  }

  getCaptchaInstance = (instance) => {
    this.setState({
      captchaInstance: instance,
    })
  }

  onVerify = (data) => {
    this.setState({
      validationData: data,
      validate: true,
    })
  }

  selectSwitch = (key, value) => {
    let param = {}
    param[key] = value
    this.setState(param)
  }

  changeToCollege = (school = "") => {
    this.setState({
      schoolName: school,
    })
  }

  fetchCollegeInfo = () => {
    const { list, schoolName } = this.state
    const data = list.filter((item) => item["school"] === schoolName)
    if (data.length > 0) {
      return data[0]["colleges"]
    }
    return []
  }

  render() {
    const { country } = this.props
    const {
      validate,
      captchaInstance,
      isSchool,
      isCollege,
      schoolList,
      schoolName,
    } = this.state
    const collegeList = this.fetchCollegeInfo()
    return (
      <>
        <HeaderBar title={"助学计划"} arrow={true} />
        <Header />
        <Formik
          initialValues={{
            tel: "",
            password: "",
            code: "",
            school: "",
            college: "",
            uid: "",
            name: "",
          }}
          validate={({ tel, password, code, school, college, uid, name }) => {
            let errors = {}
            if (!/\d/.test(tel)) {
              errors.tip = "请填写正确格式的手机号~"
              return errors
            }
            if (
              password.length < 6 ||
              !/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(password)
            ) {
              errors.tip = "密码需要包含6-16位字母和数字~"
              return errors
            }
            if (!/[0-9]{6}/.test(code)) {
              errors.tip = "请填写验证码(先滑块验证呦)~"
              return errors
            }
            if (!school) {
              errors.tip = "请选择学校~"
              return errors
            }
            if (!college) {
              errors.tip = "请选择学院~"
              return errors
            }
            if (!uid) {
              errors.tip = "请填写学号~"
              return errors
            }
            if (!name) {
              errors.tip = "请填写姓名~"
              return errors
            }
            return {}
          }}
          onSubmit={(values, errors) => {
            this.handleToLogin(values)
          }}
        >
          {(props) => {
            const isSubmit =
              Object.values(props.values).join("") !== "" &&
              props.errors.tip === undefined
            return (
              <Form className="student-form">
                <Field name="tel">
                  {({ field }) => (
                    <Input
                      {...field}
                      type={"tel"}
                      placeholder={"手机号快捷登录(免注册)"}
                      wrapperClass={"tel-input"}
                      country={country}
                    />
                  )}
                </Field>
                <div className="student-form__item">
                  <Field
                    className="student-form__input"
                    type="password"
                    name="password"
                    minLength="6"
                    maxLength="16"
                    placeholder="密码需要包含6-16位字母和数字"
                  />
                </div>
                <CaptchaAli
                  getInstance={this.getCaptchaInstance}
                  onVerify={this.onVerify}
                  mb={15}
                />
                {validate && (
                  <Field type="number" name="code">
                    {({ field }) => (
                      <VeriCodeInput
                        {...field}
                        className={"student-form__code"}
                        icon={
                          <i
                            className={"iconfont iconduanxin"}
                            style={{ fontSize: "20px", left: "12px" }}
                          />
                        }
                        tel={props.values.tel}
                        validationData={this.state.validationData}
                        errors={props.errors}
                        placeholder={"请输入验证码"}
                        instance={captchaInstance}
                        country={country}
                      />
                    )}
                  </Field>
                )}
                <div className="student-form__item">
                  <label className="student-form__label">学校</label>
                  <StudentSelect
                    name="school"
                    value={props.values.school}
                    schoolName={schoolName}
                    data={{
                      key: "isSchool",
                      val: isSchool,
                    }}
                    options={schoolList}
                    placeholder="请选择"
                    onChange={props.setFieldValue}
                    clearToCollege={() => {
                      props.setFieldValue("college", "")
                    }}
                    selectSwitch={this.selectSwitch}
                    changeToCollege={this.changeToCollege}
                  />
                </div>
                <div className="student-form__item">
                  <label className="student-form__label">学院</label>
                  <StudentSelect
                    name="college"
                    value={props.values.college}
                    data={{
                      key: "isCollege",
                      val: isCollege,
                    }}
                    isClick={props.values.school !== ""}
                    options={collegeList}
                    placeholder="请先选择学校"
                    onChange={props.setFieldValue}
                    selectSwitch={this.selectSwitch}
                  />
                </div>
                <div className="student-form__item">
                  <label className="student-form__label">学号</label>
                  <Field
                    className="student-form__input"
                    type="text"
                    name="uid"
                    placeholder="不区分大小写"
                  />
                </div>
                <div className="student-form__item">
                  <label className="student-form__label">姓名</label>
                  <Field
                    className="student-form__input"
                    type="text"
                    name="name"
                    placeholder="请准确填写"
                  />
                </div>
                <div className="student-form__footer">
                  {props.errors.tip && (
                    <p className="student-form__tip">*{props.errors.tip}</p>
                  )}
                  <button
                    className="student-form__submit"
                    type="submit"
                    disabled={!isSubmit}
                  >
                    注册
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </>
    )
  }
}

const StudentSelect = (props) => {
  const {
    options = [],
    data: { key = "", val = false },
    schoolName,
    isClick = true,
    name,
    value,
    onChange,
    placeholder,
    selectSwitch,
    clearToCollege,
    changeToCollege,
  } = props
  return (
    <div className="student-select">
      <input
        className={classnames({ active: val })}
        value={value}
        type="text"
        placeholder={placeholder}
        readOnly
        onClick={() => {
          isClick && selectSwitch(key, true)
        }}
      />
      {val && (
        <ul className="student-select__list">
          {options.length > 0 &&
            options.map((item, index) => (
              <li
                className="student-select__option"
                key={index}
                onClick={() => {
                  selectSwitch(key, false)
                  onChange(name, item)
                  if (
                    schoolName !== item &&
                    typeof clearToCollege === "function"
                  ) {
                    clearToCollege()
                  }
                  typeof changeToCollege === "function" && changeToCollege(item)
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

export default StudentRoot
