import React from "react"
import "./header.scss"
import logo from "./logo.png"

const Header = React.memo(() => {
  return (
    <div className="common-header">
      <img src={logo} alt="" />
    </div>
  )
})

export default Header
