import React, { Component } from "react"
import "./index.scss"
import Input from "../Input"
import { Link } from "react-router-dom"

class InputWithCountryCodes extends Component {
  render() {
    const { country, ...rest } = this.props
    return (
      <div className={"input-with-country-codes"}>
        <div className="country-codes">
          <Link to={"/country"}>
            +{(country && country.num) || 86}
            <i className={"iconfont iconiconfront-69"} />
          </Link>
        </div>
        <Input {...rest} />
      </div>
    )
  }
}

export default InputWithCountryCodes
