import React, { Component } from "react"
import "./../index.scss"
import { http } from "src/utils"
import { Link } from "react-router-dom"
import { Toast } from "antd-mobile"

class TeacherList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      active: "",
      isToggleOn: false,
      liClass1: "",
    }
  }
  bgChange = (index) => {
    console.log(index, 999)
    this.setState({
      active: index,
    })
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
      liClass1: prevState.isToggleOn ? "" : "active1",
    }))
  }

  componentDidMount() {
    http.get(`${API.home}/m/mobTeachers`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data,
        })
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }

  render() {
    const { active, liClass1, isToggleOn } = this.state
    return (
      <div className="teacher">
        <p className="cont">超强师资团队</p>
        <div className="teabox">
          {this.state.data.map((item, i) => {
            const liClass = active === i && isToggleOn ? "active1" : ""
            // const liClass1 = isToggleOn ? 'active1' : '';
            return (
              <div
                key={i}
                className={`tealist ${liClass}`}
                onClick={() => {
                  this.bgChange(i)
                }}
              >
                <div className="pic">
                  <img src={item.thumb_img} alt="" />
                </div>
                <div className="detail">
                  <h2 className="name">
                    <b>{item.teacher_name}</b>
                  </h2>
                  <p>
                    <span className="title">{item.title}</span>
                  </p>
                  <p className="desc">{item.desc} </p>
                </div>
              </div>
            )
          })}
        </div>
        <div className="consult">
          <a href="https://live.easyliao.com/live/chat.do?c=27526&g=53481&config=81372">
            咨询更多师资信息
          </a>
        </div>
      </div>
    )
  }
}

export default TeacherList
