/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import "./course.scss"

const Course = (props) => {
  return (
    <li className={`course-item ${props.className}`}>
      {props.top}
      <a onClick={() => props.toDetail(props.id)}>
        <img src={props.img} alt="" />
        {props.status}
        <p className={`course-title ${props.className}`}>{props.title}</p>
      </a>
      {props.bottom}
    </li>
  )
}

export default Course
