import { RECEIVE_COURSES_DETAIL, ADD_COURSES_TO_CART } from "./actions"

const initialState = {}

export default function detailInfo(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COURSES_DETAIL:
      let courseInfo = action.payload
      return {
        ...state,
        ...courseInfo,
      }
    case ADD_COURSES_TO_CART:
      const { course_info } = state
      const obj = {
        course_info: Object.assign({}, course_info, { in_cart: true }),
      }
      return Object.assign({}, state, obj)
    default:
      return state
  }
}
