/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react"
import "./wechatLogin.scss"
import Input from "../common/inputWithCountryCodes"
import LoginButton from "../common/LoginButton"
import LoginWays from "../common/LoginWays"
import Header from "../common/Header"
import VeriCodeInput from "../common/veriCodeInput"
import { Form, Field, withFormik } from "formik"
import { quickLogin } from "src/store/userAction"
import { connect } from "react-redux"
import { compose } from "redux"
import { isEmpty } from "lodash"
import { Toast } from "antd-mobile"
import { HeaderBar, CaptchaAli } from "src/common"

class Login extends Component {
  state = {
    validate: null,
    captchaInstance: null,
    validationData: null,
  }

  loginWaysClick = (method) => {
    const { history, loginWays, location } = this.props

    const item = loginWays.find((item) => item.text === method)

    let from = (location.state && location.state.from) || {
      pathname: "/",
      search: window.location.search,
      hash: "",
    }
    const referrer = document.referrer
    const redirectURI =
      !/^https?:\/\/m.julyedu.com\/?$/.test(referrer) &&
      !/\.julyedu\.com\/live\/m_room/.test(referrer) &&
      referrer
        ? referrer
        : window.location.origin + from.pathname + from.search + from.hash

    switch (method) {
      case "账号登录":
        history.push(
          {
            pathname: "/passport/account-login",
            search: window.location.search,
          },
          location.state
        )
        break
      case "微信":
        window.location.assign(
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx23dac6775ac82877&redirect_uri=${encodeURIComponent(
            redirectURI
          )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        )
        break
      case "QQ":
        window.location.assign(
          `${
            API["passport-api"]
          }/m/login/qqLogin?redirect_url=${encodeURIComponent(redirectURI)}`
        )
        break
      default:
        window.location.assign(item.url)
    }
  }

  getCaptchaInstance = (instance) => {
    this.setState({
      captchaInstance: instance,
    })
  }

  onVerify = (data) => {
    this.setState({
      validationData: data,
      validate: true,
    })
  }

  toStudentRegister = () => {
    const { history } = this.props
    history.push("/passport/student-login")
  }

  componentDidMount() {
    const { location } = this.props
    const { action } = this.props.history
    let pathname =
      location.state && location.state.from && location.state.from.pathname
    let search =
      location.state && location.state.from && location.state.from.search

    if (action !== "POP") {
      let historyUrl = `${pathname}${search}`
      window.localStorage.setItem("HistoryUrl", historyUrl)
    }
  }

  render() {
    const { loginWays, errors, values, country } = this.props
    return (
      <div className="login">
        <HeaderBar title={"登录"} arrow={true} />
        <Header />
        <Form className="login-info">
          <Field name="tel">
            {({ field }) => (
              <Input
                {...field}
                type={"tel"}
                placeholder={"手机号快捷登录(免注册)"}
                wrapperClass={"tel-input"}
                country={country}
                id={"tel"}
              />
            )}
          </Field>
          {this.state.validate && (
            <Field type="number" name="veriCode">
              {({ field }) => (
                <VeriCodeInput
                  {...field}
                  className={"verification"}
                  icon={
                    <i
                      className={"iconfont iconduanxin"}
                      style={{ fontSize: "20px", left: "12px" }}
                    />
                  }
                  tel={values.tel}
                  errors={errors}
                  placeholder={"请输入验证码"}
                  instance={this.state.captchaInstance}
                  validationData={this.state.validationData}
                  country={country}
                />
              )}
            </Field>
          )}
          <CaptchaAli
            onVerify={this.onVerify}
            getInstance={this.getCaptchaInstance}
          />

          <LoginButton
            active={values.tel && values.veriCode && isEmpty(errors)}
          />

          {/* 助学计划 */}
          <div className="student-root">
            <a
              className="student-root__button"
              onClick={this.toStudentRegister}
            >
              助学计划
            </a>
          </div>
        </Form>
        <LoginWays onClick={this.loginWaysClick} loginWays={loginWays} />
      </div>
    )
  }
}

const FormikConfig = {
  mapPropsToValues: () => ({
    tel: "",
    veriCode: "",
  }),
  handleSubmit(values, { props }) {
    const from = props.location.state && props.location.state.from
    props
      .quickLogin({
        phone_num: values.tel,
        phone_code: values.veriCode,
        area_code: "00" + props.country.num,
        redirect:
          from &&
          encodeURIComponent(
            window.location.origin + from.pathname + from.search + from.hash
          ),
      })
      .then((res) => {
        if (res.hasError) {
          Toast.info(res.msg)
        } else {
          let state = props.location.state || { from: { pathname: "/" } }

          props.history.replace(state.from)
        }
      })
  },
  validateOnChange: true,
  validate: (values) => {
    let errors = {}
    if (!/\d/.test(values.tel)) {
      errors.tel = "请填写正确格式的手机号"
    }
    if (!/[0-9]{6}/.test(values.veriCode)) {
      errors.veriCode = "请输入验证码"
    }
    return errors
  },
}

export default compose(
  connect((state) => ({ country: state.country }), { quickLogin }),
  withFormik(FormikConfig)
)(Login)
