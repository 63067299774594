import React, { Component } from "react"
import "./index.scss"
import VeriCodeInput from "../common/veriCodeInput"
import Button from "../common/Button"
import { withFormik, Form, Field } from "formik"
import { connect } from "react-redux"
import { compose } from "redux"
import { Toast } from "antd-mobile"
import { HeaderBar, ClearableInput, CaptchaAli } from "src/common"
import { validateEmail, http } from "src/utils"
import { quickLogin } from "src/store/userAction"
import OnSubmissionError from "../common/OnSubmissionError"
import { Link } from "react-router-dom"

class ForgotPassword extends Component {
  state = {
    validate: null,
    captchaInstance: null,
    validationData: null,
  }

  getCaptchaInstance = (instance) => {
    this.setState({
      captchaInstance: instance,
    })
  }
  onVerify = (data) => {
    this.setState({
      validationData: data,
      validate: true,
    })
  }
  onSubmissionError = () => {
    const errors = Object.values(this.props.errors)
    errors.length && Toast.info(errors[0], 2, null, false)
  }

  render() {
    const { values, isValid } = this.props
    return (
      <div className={"forgot-password-email"}>
        <HeaderBar title="忘记密码" arrow={true} />
        <div className="content">
          <Form className="forgot-password-form">
            <Field
              name={"email"}
              render={({ field, form }) => {
                return (
                  <ClearableInput
                    {...field}
                    type={"email"}
                    placeholder={"请输入注册时的邮箱账号"}
                    wrapperClass={"email-input"}
                    setFieldValue={form.setFieldValue}
                  />
                )
              }}
            />
            {this.state.validate && (
              <Field
                name="veriCode"
                render={({ field }) => {
                  return (
                    <VeriCodeInput
                      {...field}
                      className={"verify-code"}
                      icon={
                        <i
                          className={"iconfont iconduanxin"}
                          style={{ fontSize: "20px", left: "12px" }}
                        />
                      }
                      email={values.email}
                      challenge={this.state.validate}
                      instance={this.state.captchaInstance}
                      validationData={this.state.validationData}
                      action={"auth"}
                      checking={1}
                    />
                  )
                }}
              />
            )}
            <OnSubmissionError callback={this.onSubmissionError} />
            {/*<Captcha getInstance={this.getCaptchaInstance} onVerify={this.onVerify}/>*/}
            <CaptchaAli
              getInstance={this.getCaptchaInstance}
              onVerify={this.onVerify}
            />
            <Button className={"next_step"} active={isValid}>
              下一步
            </Button>
            <Link
              className={"to-phone"}
              to={"/passport/forgot-password"}
              replace
            >
              手机号找回
            </Link>
          </Form>
        </div>
      </div>
    )
  }
}

const formikConfig = {
  mapPropsToValues: () => ({
    email: "",
    veriCode: "",
  }),
  validateOnChange: true,
  validateOnBlur: true,
  validate: (values) => {
    let errors = {}
    if (!validateEmail(values.email)) {
      errors.email = "请输入正确的邮箱地址"
    }
    values.veriCode.toString().length !== 6 &&
      (errors.veriCode = "验证码格式不正确")
    return errors
  },
  handleSubmit(values, { props }) {
    sessionStorage.setItem("r_type", "email")
    sessionStorage.setItem("email", values.email)
    http
      .post(`${API["passport-api"]}/check_email_code`, {
        email: values.email,
        code: values.veriCode,
      })
      .then((res) => {
        if (res.data.errno === 0) {
          props.history.push("/passport/set-password", {
            from: props.location,
          })
        } else {
          Toast.info(res.data.msg, 2, null, false)
        }
      })
  },
}

export default compose(
  connect(null, { quickLogin }),
  withFormik(formikConfig)
)(ForgotPassword)
