import { http } from "src/utils"

export const RECEIVE_MY_COURSES = "RECEIVE_MY_COURSES"
export const receiveMyCourses = (payload) => ({
  type: RECEIVE_MY_COURSES,
  payload,
})

const PAGE_INTERVAL = 1
export const NUM_INTERVAL = 10
export const fetchCoursesListIfNeeded = () => (dispatch, getState) => {
  const myCourses = getState().myCourses
  const { switchTab, page, noMore } = myCourses
  if (!switchTab && !noMore) {
    dispatch(
      getMyCourses({
        page: page + PAGE_INTERVAL,
        num: NUM_INTERVAL,
      })
    )
  }
}

export const getMyCourses = (payload) => (dispatch) => {
  dispatch(startFetchingCourses)
  return http
    .get(`${API.home}/m/my_course/${payload.page}/${payload.num}`)
    .then((res) => {
      const { data, code, msg } = res.data
      if (code === 200 && data.length === 0) {
        dispatch(nomoreCourse())
        return
      }
      if (code === 200 && data.length % 10 !== 0) {
        dispatch(nomoreCourse())
      }
      dispatch(
        receiveMyCourses({
          courseList: data,
          statusCode: code,
          msg: msg,
          page: payload.page,
          num: payload.num,
        })
      )
    })
}

export const SWITCH_TAB = "SWITCH_TAB"
export const switchTab = (payload) => ({
  type: SWITCH_TAB,
  payload,
})

export const NOMORE_COURSE = "NOMORE_COURSES"
export const nomoreCourse = (payload) => ({
  type: NOMORE_COURSE,
  payload,
})

export const START_FETCHING_COURSES = "START_FETCHING_COURSES"
export const startFetchingCourses = () => {
  return { type: START_FETCHING_COURSES, payload: { isLoading: true } }
}
